import { Alert } from '@interstate/components/Alert';
import { AuxButton } from '@interstate/components/Button';
import { deskingSelectors } from '@makemydeal/dr-dash-store';
import { useDispatch, useSelector } from 'react-redux';
import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';

export const DeskingAlerts = () => {
    const dispatch = useDispatch();
    const alerts = useSelector(deskingSelectors.getDeskingAlerts);
    if (alerts.length === 0) return null;

    return (
        <>
            <InterstateThemeProvider
                themeName="Interstate"
                applicationName="Manager View"
                scopeName="Manager View"
                themeRegistries={[interstateThemeRegistry]}
            >
                {alerts.map((alert, alertIndex) => (
                    <Alert
                        type={alert.type}
                        key={`alert-${alertIndex}`}
                        role=""
                        data-testid={`desking-alert-${alertIndex}`}
                        title={alert.title}
                    >
                        {alert.message}
                        {alert.ctaAction != null && (
                            <AuxButton
                                data-testid="cta-button"
                                onClick={() => {
                                    dispatch(alert.ctaAction);
                                }}
                            >
                                {alert.cta}
                            </AuxButton>
                        )}
                    </Alert>
                ))}
            </InterstateThemeProvider>
        </>
    );
};
