import styled from 'styled-components';

export const DeskingCardsContainer = styled.div<{ cardsAmount: number }>`
    display: grid;
    overflow: auto;
    grid-auto-flow: column;
    grid-gap: ${({ theme }) => theme.tokens.ComponentCardPadding};
    grid-template-columns: ${({ cardsAmount }) => `repeat(${cardsAmount}, minmax(450px, auto));`};
    grid-template-rows:
        [header] auto
        [payment-details] auto
        [terms] auto
        [trade] auto
        [tax] auto
        [fees] auto
        [rebates] auto
        [vpp] auto
        [accessories] auto
        [comments] auto
        [actions] auto;

    margin-top: ${({ theme }) => theme.tokens.ComponentCardPadding};
`;

export const DeskingCardHeader = styled.div`
    grid-row-start: header;
    grid-column: 1 / -1;

    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const DeskingCardHeaderActions = styled.div`
    display: grid;

    grid-template-columns: 1fr 7fr;
    grid-template-rows: minmax(40px, 1fr);
    align-items: center;

    border-bottom: 1px solid ${({ theme }) => theme.tokens.SemColorBorderDefault};
    padding-bottom: 8px;
`;

export const DeskingCardHeaderActionsMenu = styled.div`
    justify-self: end;
    grid-column: 2 / -1;

    & * {
        margin-left: 8px;
    }
`;

export const DeskingCardLeftPanel = styled.div`
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;
    grid-row: 2 / -1;
    grid-column-start: 1;
`;

export const DeskingCardLeftPanelContent = styled.div`
    grid-row: 2 / -1;
`;

export const DeskingCardBlocksContainer = styled.div`
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;
    grid-row: 2 / -1;
    grid-column-start: 2;
`;

export const StyledDeskingCard = styled.div<{ isSingleCardView: boolean }>`
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: ${({ isSingleCardView }) =>
        isSingleCardView ? '[left-side] 26rem [right-side] minmax(0, 1fr)' : 'subgrid'};
    grid-row: -1 / 1;

    grid-auto-rows: max-content;

    grid-gap: ${({ theme }) => theme.tokens.ComponentCardPadding};
    padding: ${({ theme }) => theme.tokens.ComponentCardPadding};

    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.tokens.BaseColorGray300};
    border-radius: ${({ theme }) => theme.tokens.BaseSizeBorderRadiusSm};
`;

export const DeskingCardBlock = styled.div<{ rowName: string }>`
    grid-row-start: ${({ rowName }) => rowName};
`;
