import React from 'react';
import { Route } from 'react-router-dom';

import { ICreditDecisionActivity } from '../types/ICreditDecisionActivity';
import { getAllRoutes } from '../utils/routes';

import CreditDecisionActivity from './CreditDecisionActivity';
import { ActivityRouteContainer } from './CreditDecisionActivity.style';
import { ScopedStateProvider } from '@makemydeal/dr-shared-store';

const Root = (props: ICreditDecisionActivity) => {
    const { root } = getAllRoutes(props.baseRoute);

    return (
        <ActivityRouteContainer data-testid="activities-credit-decision-container" className="activities-credit-decision">
            <ScopedStateProvider navigationScope>
                <Route path={root} component={CreditDecisionActivity} />
            </ScopedStateProvider>
        </ActivityRouteContainer>
    );
};

Root.displayName = 'CreditDecisionActivity';

export default Root;
