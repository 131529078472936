import { Button } from '@interstate-beta/components/Button';
import { ModalDialog } from '@interstate-beta/components/ModalDialog';
import { Typography } from '@interstate-beta/components/Typography';
import { offerReduxActionCreators } from '@makemydeal/dr-dash-store';
import { useDispatch } from 'react-redux';
import { CoBuyerModalProps } from './CoBuyerModalProps';

export const CoBuyerRemoveModal = ({ show, setShowModal }: CoBuyerModalProps) => {
    const dispatch = useDispatch();
    return (
        <ModalDialog
            size="small"
            htmlId="remove-cobuyer-modal"
            data-testid="remove-cobuyer-modal"
            show={show}
            header={
                <Typography variant="h3" color="base.color.blue.700">
                    Remove Co-Buyer
                </Typography>
            }
            footer={
                <>
                    <Button buttonStyle="tertiary" htmlId="cancel-btn" data-testid="cancel-btn" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>

                    <Button
                        htmlId="remove-cobuyer-btn"
                        data-testid="remove-cobuyer-btn"
                        onClick={() => {
                            dispatch(offerReduxActionCreators.clearCoBuyer());
                            setShowModal(false);
                        }}
                    >
                        Remove Co-Buyer
                    </Button>
                </>
            }
        >
            Are you sure you want to remove this co-buyer? Remember, you will still need to update this info in your credit portal.
        </ModalDialog>
    );
};
