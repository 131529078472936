export const DEBOUNCE_TIMER = 2000;

export const ACCESSORIES = 'Accessories';
export const ACQUISITION_FEE_ABBR = 'Acq Fee';
export const ACQUISITION_FEE_VALUE_WHEN_TRUE = 'upfront';
export const ACQUISITION_FEE_OPTION_LABEL = 'Upfront?';
export const ACV = 'ACV';
export const ADD_ACCESSORIES = 'Add Accessories';
export const ADDL_TAXES = 'Additional Taxes';
export const ADDS_TO_CAP_COST = 'Adds to Cap Cost';
export const ADJUSTED_CAP_COST = 'Adjusted Cap Cost';
export const ADJUSTED_RESIDUAL = 'Adj Residual';
export const ADJUSTED_RESIDUAL_VALUE = 'Adj Residual Value';
export const ALLOWANCE = 'Allowance';
export const AMOUNT = 'Amount';
export const COST = 'Cost';
export const SALE = 'Sale';
export const RESIDUAL = 'Residual';
export const NAME = 'Name';
export const APR = 'APR';
export const BACK = 'Back';
export const BASE_MO_PAYMENT = 'Base Mo. Payment';
export const BASE_RESIDUAL = 'Base Residual';
export const BASE_RESIDUAL_VALUE = 'Base Residual Value';
export const BODY_STYLE = 'Body Style';
export const BUY_RATE = 'Buy Rate';
export const CAP_COST = 'Cap Cost';
export const CAP_COST_REDUCTION = 'Cap Cost Reduction';
export const CAPITALIZED_TAXES = 'Capitalized Taxes';
export const CASH_DOWN = 'Cash Down';
export const CASH_PURCHASE_PRICE = 'Cash Purchase Price';
export const CITY_LABEL = 'City';
export const COPY_URL_BUTTON_TEXT = 'Copy';
export const COUNTY_LABEL = 'County';
export const CODE = 'Code';
export const ID = 'ID';
export const CREDIT_DECISION_LENDER_NAME = 'Lender';
export const CREDIT_DECISION_LENDER_NAME_FIELD = 'CreditDecisionLenderNameField';
export const CREDIT_DECISION_STATUS = 'Lender Decision Status';
export const CREDIT_DECISION_TOGGLE = 'Official Credit Decision';
export const CREDIT_SCORE = 'Credit Score';
export const DEPRECIATION = 'Depreciation';
export const DISCOUNT = 'Discount';
export const DOORS = 'Doors';
export const DRIVE_LINE = 'Drive Line';
export const ENGINE = 'Engine';
export const EST_PAYMENT = 'Est. Payment';
export const EXCESS_MILEAGE_CHARGE = '$/Mi';
export const EXCESS_MILEAGE_PLACEHOLDER = '20';
export const EXISTING_MILEAGE = 'Existing Mileage';
export const EXISTING_MILEAGE_PERCENT = 'Existing Mileage %';
export const EXPECTED_ANNUAL_MILES = 'Exp Miles';
export const EXPECTED_ANNUAL_MILES_PLACEHOLDER = '20,000';
export const FEES = 'Fees';
export const FINANCE_TERM = 'Finance Term';
export const FIRST_PAYMENT_ABBR = '1st Payment';
export const FRONT = 'Front';
export const GENERIC_LAST_FIELD_CHANGED_ERROR = 'Adjustment did not return a valid payment.';
export const GOV_FEES = 'Gov. Fees';
export const GROSS_CAP_COST = 'Gross Cap Cost';
export const INCENTIVE_DUPLICATE_CODE = 'Duplicate code';
export const INCLUDE_TRADE_WITH_OFFER = 'Include trade with offer*';
export const INSTALL = 'Install';
export const INTERIOR_COLOR = 'Interior Color';
export const IS_BUYER_REGISTERED_OWNER = 'The buyer is the registered owner of this vehicle.';
export const LEASE = 'Lease';
export const LEASE_TERM = 'Lease Term';
export const LEASE_TRADE = 'Lease Trade';
export const LENGTH_OF_LOAN = 'Length of Loan';
export const LIEN_HOLDER = 'Lienholder';
export const LIEN_HOLDER_ACCOUNT_NUMBER = 'Account/Loan Number';
export const LIEN_HOLDER_ADDRESS_BANK = 'Bank Address';
export const LIEN_HOLDER_ADDRESS_CITY = 'City';
export const LIEN_HOLDER_ADDRESS_STATE = 'State';
export const LIEN_HOLDER_ADDRESS_ZIP = 'ZIP';
export const LOCAL_LABEL = 'Local';
export const MAX_RESIDUALIZED_MSRP = 'MRM';
export const MAX_RESIDUALIZED_MSRP_APPLIED = 'MAX_RESIDUALIZED_MSRP_APPLIED';
export const MAX_RESIDUALIZED_MSRP_APPLIED_LABEL = 'Included?';
export const MAX_RESIDUALIZED_MSRP_APPLIED_VALUE_WHEN_TRUE = 'included';
export const MILES_PER_YEAR = 'Miles Per Year';
export const MILES_PER_YEAR_PERCENT = 'Miles Per Year %';
export const MO_PAYMENT = 'Mo. Payment';
export const MO_USE_TAX = 'Mo. Use Tax';
export const MO_USE_TAXES = 'Mo. Use Taxes';
export const MONEY_FACTOR = 'MF';
export const MONEY_FACTOR_TYPE = 'MoneyFactor';
export const MONTHLY_PAYMENT = 'Monthly Payment';
export const MSRP = 'MSRP';
export const NET_SELLING_PRICE = 'Net Selling Price';
export const NET_TRADE = 'Net Trade';
export const NOT_AVAILABLE = 'N/A';
export const NOT_AVAILABLE_DASH = '--';
export const ODOMETER = 'Odometer';
export const OUT_OF_POCKET = 'Out of Pocket';
export const PAYMENT_FREQUENCY = 'Payment Frequency';
export const PAYOFF = 'Payoff';
export const PAYOFF_DUE_DATE = 'Payoff Due Date';
export const PER_DIEM = 'Per Diem';
export const PRIMARY_TAXES = 'Primary Taxes';
export const PROFIT = 'Profit';
export const PROGRAMS_AND_INCENTIVES = 'Programs and Incentives';
export const PROTECTION_PRODUCTS = 'Protection Products';
export const PURCHASE = 'Purchase';
export const RATE = 'Sell Rate';
export const RATE_TYPE = 'Rate Type';
export const REBATES = 'Rebates';
export const RENT_CHARGED = 'Rent Charged';
export const REQUIRED = 'Required';
export const RETAIL_PRICE = 'Retail Price';
export const SECURITY_DEPOSIT = 'Security Deposit';
export const SELL_RATE = 'Sell Rate';
export const SELLING_PRICE = 'Selling Price';
export const SELF_REPORTED_CREDIT_RATING = 'Self Reported Credit Rating';
export const SELF_REPORTED_CREDIT_RATING_FIELD = 'SelfReportedCreditRatingField';
export const SHARE_EMAIL_OPTION_LABEL = 'Share via Email';
export const SHARE_MESSAGE_PLACEHOLDER = 'Include a message about this offer';
export const SHARE_URL_INPUT_LABEL = 'Share URL';
export const SHARE_URL_OPTION_LABEL = 'Share URL';
export const SHOPPER_EMAIL_INPUT_LABEL = 'Shopper Email Address';
export const STATE_LABEL = 'State';
export const TAX_CAP_COST_RED = 'Tax on Cap Cost Red.';
export const TAXES = 'Taxes';
export const TERM = 'Term';
export const TOTAL_ADD_ONS = 'Total Add-ons';
export const TOTAL_AMOUNT_DUE = 'Total Amount Due';
export const TOTAL_BASE_MO_PAYMENTS = 'Tot. Base Mo. Payments';
export const TOTAL_CAP_REDUCTION = 'Total Cap Reduction';
export const TOTAL_CONDITIONAL_INCENTIVES = 'Total Conditional Incentives';
export const TOTAL_DUE_AT_SIGNING = 'Tot. Due at Signing';
export const TOTAL_FINANCED = 'Total Financed';
export const TOTAL_MANUFACTURER_INCENTIVES = 'Total Manufacturer Incentives';
export const TOTAL_PROTECTION = 'Total Protection';
export const TOTAL_RESIDUAL = 'Total Residual';
export const TRADE_ACV = 'Trade ACV';
export const TRADE_ALLOWANCE = 'Trade Allowance';
export const TRADE_ALLOWANCE_METHOD = 'Trade-in Allowance Method';
export const TRADE_OFF = 'Trade Payoff';
export const TRANSMISSION = 'Transmission';
export const TYPE = 'Type';
export const UPFRONT_ADDONS = 'Upfront Add-ons';
export const UPFRONT_FEES = 'Upfront Fees';
export const UPFRONT_TAXES = 'Upfront Taxes';
export const UPFRONTS_CAPITALIZED = 'Upfronts Capitalized';
export const VEHICLE_IS_REGISTERED_IN_SAME_STATE = 'The vehicle is registered in the same state as the new vehicle.';
export const VIEW_PROGRAMS = 'View Programs';
export const VIN = 'VIN';
export const ZERO_DOLLARS = '$0.00';
export const VIN_ERROR = 'Please use 17 digit VIN';
export const EXTERIOR_COLORS = ['Beige', 'Gray', 'White', 'Blue', 'Black', 'Red', 'Other'];
export const VEHICLE_CONDITION = ['Excellent', 'Very Good', 'Good', 'Fair'];

export const ACCESSORIES_NUMERIC_MAX_VALUE = 999999;
export const ACCESSORIES_NUMERIC_MAX_VALUE_MESSAGE = 'Value should be less than 1 million.';

export enum Action {
    CONTINUE_IN_DESKING,
    SEND_TO_ECOMMERCE
}

export enum DriveLineEnums {
    AllWheelDrive = 'AllWheelDrive',
    FourWheelDrive = 'FourWheelDrive',
    FrontWheelDrive = 'FrontWheelDrive',
    RearWheelDrive = 'RearWheelDrive'
}

export enum DriveLineLabelEnums {
    AWD = 'AWD',
    FourWD = '4WD',
    FWD = 'FWD',
    RWD = 'RWD'
}

export const DriveLineOptions = [
    { label: DriveLineLabelEnums.AWD, value: DriveLineEnums.AllWheelDrive },
    { label: DriveLineLabelEnums.FourWD, value: DriveLineEnums.FourWheelDrive },
    { label: DriveLineLabelEnums.FWD, value: DriveLineEnums.FrontWheelDrive },
    { label: DriveLineLabelEnums.RWD, value: DriveLineEnums.RearWheelDrive }
];
