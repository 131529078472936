// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { urlBuilder } from '@makemydeal/dr-common-utils';
import { paymentMiddleware, paymentTypes, OfferReduxConfig } from '@makemydeal/dr-offer-redux';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { bootstrapActionTypes, initActionTypes } from '@makemydeal/dr-shared-store';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';

// consts/enums
import { APPLICATION_NAME } from '../constants';

// config
import { getServicesBff } from '../selectors/config';
import { getPaymentProducts } from '../selectors/compositeSelectors';
import { getPartnerCode, getPartnerDealerId } from '../selectors/calculationDetailsSelectors';

// utils
import { PAYMENT_RECEIVED } from '../actionTypes/deskingActionTypes';
import { applyProgramEndDays } from '../actions/offerActionCreators';
import { getPaymentFeesTypes } from '../actions/paymentFeesTypesActionCreators';
import { UPDATED_CREDIT_DECISION_ENABLED } from '../actionTypes/offerActionTypes';
import { applyDealerOverrides } from '../actions/offerRedux';
import { offerReduxSelectors } from '../selectors';

// keep a cached version of the middleware once we're able to build it.
export let cachedPaymentReduxMiddleware: ((store: DashStore) => (next: DashNext) => (action: AnyFSA) => void) | undefined;

export const paymentMiddlewareClearCache = () => (cachedPaymentReduxMiddleware = undefined);

export const paymentMiddlewareConfigSelectorsDefault: paymentTypes.HostSelectors = {
    shouldFakePaymentFailure: (_state: StateTree) => false,
    shouldKeepPaymentsOnFailure: () => true,
    getPartnerDealerId,
    getPartnerCode,
    isManagerView: true
};

export const getMiddleware =
    (selectors = paymentMiddlewareConfigSelectorsDefault): DashMiddleware =>
    (store: DashStore) =>
    (next: DashNext) =>
    (action: AnyFSA) => {
        // this will only configure when all the values we need are here from the configuration.  It will not reconfigure
        // if that configuration changes (but thats not a valid use case)
        switch (action.type) {
            case initActionTypes.INIT_SUCCESS: {
                store.dispatch(applyProgramEndDays());
                store.dispatch(getPaymentFeesTypes());
                break;
            }
            case bootstrapActionTypes.BOOTSTRAP_SUCCESS: {
                // load up the middleware
                // get what we can from state.  This typically will be the bff endpoint only since its placed as part of initial state
                const state = store.getState();
                const bff = getServicesBff(state);
                selectors.getPaymentProducts = getPaymentProducts;
                // now we will not have the toggles, static images or the gateway in state
                // so we will have to replace them

                const usePaymentGridMV = action.payload?.featureToggles.usePaymentGridMV;

                if (usePaymentGridMV) {
                    const config = OfferReduxConfig.getInstance();
                    config.setConfig('isTermIdWithDownPayment', true);
                }

                const paymentConfig: paymentTypes.IPaymentConfig = {
                    paymentUrl: urlBuilder.buildFromConfig(bff, 'payment-orchestrator-with-dealer-id'),
                    selectors,
                    sourceApplication: APPLICATION_NAME
                };

                cachedPaymentReduxMiddleware = paymentMiddleware(paymentConfig);
                break;
            }
        }

        // once we have the cached version, pass the call to it
        if (cachedPaymentReduxMiddleware) {
            cachedPaymentReduxMiddleware(store)(next)(action);
            switch (action.type) {
                case PAYMENT_RECEIVED: {
                    const state = store.getState();
                    const isUserProgramQuotes = offerReduxSelectors.getIsUserProgramQuotes(state);

                    // if we are using UPQ, we can avoid this double payment call
                    if (isUserProgramQuotes) {
                        break;
                    } else {
                        if (
                            (action.meta.originalAction.type === UPDATED_CREDIT_DECISION_ENABLED &&
                                action.meta.originalAction.meta.excludeOverrides) ||
                            action.meta.originalAction.meta.missingRequiredPSFields
                        ) {
                            store.dispatch(applyDealerOverrides());
                        }
                    }
                }
            }
        } else {
            // since the paymentRedux middleware calls next, we do not want it called twice
            // this will end up causing a middleware to be skipped
            next(action);
        }
    };
