// externals
import { useDispatch, useSelector } from 'react-redux';
import { SelectInputOption } from '@interstate/components/SelectInput';

// libraries
import {
    tradeInSelectors,
    tradeInComponentActionCreators,
    tradeInComponentSelectors,
    tradeInActionCreators
} from '@makemydeal/dr-dash-store';
import { updateVehicle } from '@makemydeal/dr-activities-tradein';

// components
import { SelectInput } from './TradeInSelectInput';

// constants
import { TRADE_IN_TRIM } from '@makemydeal/dr-dash-components';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

interface TrimFieldProps {
    isManualFormOpen: boolean;
}

const TrimField: React.FC<TrimFieldProps> = ({ isManualFormOpen }) => {
    const dispatch = useDispatch();
    const vehicleYear = useSelector(tradeInSelectors.getTradeYear);
    const vehicleMakeId = useSelector(tradeInSelectors.getTradeMakeId);
    const vehicleModelId = useSelector(tradeInSelectors.getTradeModelId);
    const vehicleTrim = useSelector(tradeInSelectors.getTradeTrim);
    const tradeInVehicleTrims = useSelector(tradeInComponentSelectors.getTradeInComponentTrims);
    const tradeInVehicle = useSelector(tradeInComponentSelectors.getTradeInComponentVehicle);
    const vehicleModel = useSelector(tradeInSelectors.getTradeModel);

    const isTrimInputDisabled = tradeInVehicleTrims.options.length === 0 || !vehicleModel;

    const getTradeInTrims = (): SelectInputOption[] =>
        tradeInVehicleTrims.options.map((trim) => ({ label: trim.name, value: trim.name })).sort();

    const onTrimChange = (event: any) => {
        const trim = tradeInVehicleTrims.options.find((trim) => trim.name === event.target.value);
        updateTrimSelector(trim);
    };

    const updateTrimSelector = (trim: { name: string; id: string }) => {
        dispatch(tradeInActionCreators.updateTradeInTrim(trim));
        dispatch(
            updateVehicle({
                ...tradeInVehicle,
                trim
            })
        );
        if (trim) {
            dispatch(
                tradeInComponentActionCreators.fetchTradeInComponentVehicle(
                    MANAGER_VIEW_APP_PREFIX,
                    vehicleYear,
                    vehicleMakeId,
                    vehicleModelId,
                    trim.id
                )
            );
        }
    };

    return (
        <>
            {isManualFormOpen && (
                <SelectInput
                    data-testid="trim-input"
                    label={TRADE_IN_TRIM}
                    name={TRADE_IN_TRIM}
                    onChange={onTrimChange}
                    options={getTradeInTrims()}
                    value={vehicleTrim}
                    placeholder="Select"
                    disabled={isTrimInputDisabled}
                    required
                    displayDeselectOption={false}
                />
            )}
        </>
    );
};

export default TrimField;
