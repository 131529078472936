export const createScopedActionMeta = (targetScope: string, existingMeta: Record<string, any> = {}) => ({
    ...existingMeta,
    __scopeMeta: {
        targetScope
    }
});

export const getTargetScopeFromAction = (action: any) => {
    return action?.meta?.__scopeMeta?.targetScope;
};
