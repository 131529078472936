import React, { useMemo } from 'react';

import { ACQUISITION_FEE_ABBR, GENERIC_LAST_FIELD_CHANGED_ERROR } from '../../constants';
import { deskingSelectors, offerActionCreators, offerSelectors, offerActionTypes } from '@makemydeal/dr-dash-store';
import { usePaymentCall } from '../../utils/usePaymentCall';
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';
import { useSelector } from 'react-redux';
import { NumericInput } from '@interstate/components/NumericInput';

const AcquisitionFeeAmountField = () => {
    const { updateAcqFeeOverride } = offerActionCreators;

    const { value, handleChange, handleBlur } = usePaymentCall(offerSelectors.getAcqFeeOverrideWithFallback, updateAcqFeeOverride, {
        formatValueToString: (dollars: any) => formatDollarsAndCents(dollars)
    });

    const failed = useSelector(deskingSelectors.wasTheLastFailure(offerActionTypes.UPDATED_ACQFEE_OVERRIDE));
    const failedMessage = useMemo(() => (failed ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined), [failed]);

    return (
        <NumericInput
            id={kebabCase(ACQUISITION_FEE_ABBR)}
            hasError={failed}
            errorMessage={failedMessage}
            label={ACQUISITION_FEE_ABBR}
            name={kebabCase(ACQUISITION_FEE_ABBR)}
            placeholder="$0"
            value={value}
            data-testid={kebabCase(ACQUISITION_FEE_ABBR)}
            onChange={handleChange}
            onBlur={handleBlur}
            allowDecimal={true}
            decimalMaxLength={2}
            decimalMinLength={2}
            inputPrefix={'$'}
            autoInsertCommas={true}
        />
    );
};

export default AcquisitionFeeAmountField;
