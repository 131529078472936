import { Button } from '@interstate-beta/components/Button';
import { DocumentDuplicateIcon, LinkIcon } from '@interstate-beta/components/Icons';
import { TextInput } from '@interstate-beta/components/TextInput';
import { COPY_URL_BUTTON_TEXT } from '../constants';

export interface ISendToCustomerDialogFooterProps {
    prefix: string;
    shareUrl: string;
    handleShareUrlChange(): void;
    handleCopyClick(): void;
}

const SendToCustomerDialogFooterInterstate = ({
    prefix,
    shareUrl,
    handleShareUrlChange,
    handleCopyClick
}: ISendToCustomerDialogFooterProps) => {
    return (
        <>
            <div data-testid="send-to-customer-dialog-footer" style={{ display: 'flex', alignItems: 'flex-end', gap: '10px' }}>
                <TextInput
                    label="Copy URL"
                    name={`${prefix}-share-url-input`}
                    onChange={handleShareUrlChange}
                    data-testid={`${prefix}-share-url-input`}
                    value={shareUrl}
                    disabled
                    inputPrefix={<LinkIcon />}
                />
                <Button
                    data-testid={`${prefix}-copy-url-button`}
                    size="default"
                    onClick={handleCopyClick}
                    startIcon={<DocumentDuplicateIcon />}
                    type="submit"
                >
                    {COPY_URL_BUTTON_TEXT}
                </Button>
            </div>
        </>
    );
};

export default SendToCustomerDialogFooterInterstate;
