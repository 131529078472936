import styled from 'styled-components';
import { Grid } from '@interstate/components/Grid';

export const GridItem = styled(Grid)`
    border: 1px solid #b2b2b2;
    width: 120px;
    height: 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
`;

export const StyledGridItem = styled(Grid)<{ isSelected?: boolean }>`
    border: 1px solid #b2b2b2;
    width: 120px;
    height: 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
`;

export const LoadingIndicatorWrapper = styled.div`
    position: relative;

    & #interstitial-overlay-root {
        background-color: transparent;
    }
    & #ids-spinner-root {
        color: gray;
    }
    & #interstitial-content-root {
        color: gray;
    }
`;
