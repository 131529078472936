import { useInterstateTheme } from '@interstate-beta/components/InterstateThemeProvider';

import ChangeVehicleInterstate from './changeVehicle/ChangeVehicle.interstate';
import { StyledChangeVehicleActivity } from './ChangeVehicleActivity.style';

const ChangeVehicleActivity = () => {
    const theme = useInterstateTheme();

    return (
        <StyledChangeVehicleActivity theme={theme}>
            <ChangeVehicleInterstate />
        </StyledChangeVehicleActivity>
    );
};

export default ChangeVehicleActivity;
