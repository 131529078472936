import { SCOPES_CHANGE_DEFAULT_SCOPE, SCOPES_CLONE_SCOPE } from '../actionTypes/scopedActionTypes';

export const changeDefaultScope = (scopeId: string) => ({
    type: SCOPES_CHANGE_DEFAULT_SCOPE,
    payload: {
        newDefaultScope: scopeId
    }
});

export const cloneScope = (scopeToClone: string, metadata: any = {}) => ({
    type: SCOPES_CLONE_SCOPE,
    payload: {
        scopeToClone,
        metadata
    }
});
