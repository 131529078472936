import { useSelector } from 'react-redux';

import { Grid } from '@interstate/components/Grid';

import { CASH } from '@makemydeal/dr-platform-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

import { TermsTab } from './common/Terms.style';
import RetailPriceField from './common/RetailPriceField.interstate';
import SellingPriceField from './common/SellingPriceField.interstate';
import PaymentGridToggle from './common/PaymentGridToggle';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

const Cash = () => {
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const usePaymentGridMV = useSelector(featureToggleSelectors.usePaymentGridMV);

    if (currentOfferType !== CASH) return null;
    return (
        <TermsTab data-testid="cash-terms">
            {usePaymentGridMV && (
                <Grid xs={12}>
                    <PaymentGridToggle currentOfferType={currentOfferType} disabled={true} />
                </Grid>
            )}
            <Grid container spacing={2}>
                <Grid xs={6}>
                    <RetailPriceField />
                </Grid>
                <Grid xs={6}>
                    <SellingPriceField />
                </Grid>
            </Grid>
        </TermsTab>
    );
};

export default Cash;
