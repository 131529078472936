// externals
import { useSelector } from 'react-redux';

// libraries
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate-beta/components/Typography';
import { formatDollars } from '@makemydeal/dr-common-utils';

// Redux
import { offerSelectors, tradeInSelectors, consumerInsightsSelectors } from '@makemydeal/dr-dash-store';

// styles
import { DealPulseCard, DealPulseContainer, RangeTitle } from './DealPulseSection.styles';
import { CUSTOMER_RANGE_TITLE, TRADE_IN_RANGE_TITLE } from '../constants';

const DealPulse = () => {
    const downPayment = useSelector(offerSelectors.getDownPayment);
    const offerPrice = useSelector(offerSelectors.getSellingPrice);
    const monthlyPayment = useSelector(offerSelectors.getMonthlyPayment);
    const tradeValue = useSelector(tradeInSelectors.getTradeValue);
    const dealPulseData = useSelector(consumerInsightsSelectors.getConsumerInsights);

    // early scape, not rendering any UI if there is no data
    if (!dealPulseData || Object.keys(dealPulseData).length === 0) return null;

    const shouldRenderValue = (value: any) => {
        if (isNaN(value) || value <= 0) {
            return '--';
        }
        return formatDollars(value);
    };

    const offerPriceRangeText = `${shouldRenderValue(dealPulseData.preferredPriceLow)} - ${shouldRenderValue(
        dealPulseData.preferredPriceHigh
    )}`;
    const downPaymentRangeText = `${shouldRenderValue(dealPulseData.preferredDownLow)} - ${shouldRenderValue(
        dealPulseData.preferredDownHigh
    )}`;
    const monthlyPaymentRangeText = `${shouldRenderValue(dealPulseData.preferredMonthlyLow)} - ${shouldRenderValue(
        dealPulseData.preferredMonthlyHigh
    )}`;

    const dealPulseCardsInfo = [
        {
            title: 'Offering Price',
            offerValue: shouldRenderValue(offerPrice),
            dealPulseData: offerPriceRangeText
        },
        {
            title: 'Trade-In Value',
            offerValue: shouldRenderValue(tradeValue),
            dealPulseData: '--'
        },
        {
            title: 'Down Payment',
            offerValue: shouldRenderValue(downPayment),
            dealPulseData: downPaymentRangeText
        },
        {
            title: 'Monthly Payment',
            offerValue: shouldRenderValue(monthlyPayment),
            dealPulseData: monthlyPaymentRangeText
        }
    ];

    return (
        <>
            <Typography variant="h3" sx={{ margin: '16px 0' }} data-testid="deal-pulse-header">
                Deal Pulse
            </Typography>
            <DealPulseContainer data-testid="deal-pulse-container">
                {dealPulseCardsInfo.map(({ title, offerValue, dealPulseData }) => {
                    return (
                        <DealPulseCard key={title}>
                            <Grid container flexDirection="column" marginBottom="16px" data-testid="info-container">
                                <strong>{title}</strong>
                                <span>
                                    {offerValue}
                                    {title === 'Monthly Payment' && '/Month'}
                                </span>
                            </Grid>
                            <Grid container flexDirection="column">
                                <RangeTitle>{title === 'Trade-In Value' ? TRADE_IN_RANGE_TITLE : CUSTOMER_RANGE_TITLE}</RangeTitle>
                                <span>{dealPulseData}</span>
                            </Grid>
                        </DealPulseCard>
                    );
                })}
            </DealPulseContainer>
        </>
    );
};

export default DealPulse;
