// istanbul ignore file

// externals
import { PropsWithChildren } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Store } from 'redux';

// libraries
import CxThemeProvider from '@cx/ui/CxThemeProvider';
import { InterstateThemeProvider as InterstateBetaThemeProvider } from '@interstate-beta/components/InterstateThemeProvider';
import { interstateThemeRegistry as interstateBetaThemeRegistry } from '@interstate-beta/components/InterstateTheming/themes/interstateThemeRegistry';
import { setupAuthNetworkIntercepts } from '@makemydeal/dr-shared-network';
import { configureStore, historyInstance, ScopedStateProvider } from '@makemydeal/dr-shared-store';
import { AuthManager, ReadyContent } from '@makemydeal/dr-shared-components';
import { MANAGER_VIEW_APP_PREFIX, offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';
import { LoadingPage } from '@makemydeal/dr-dash-components';
import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';

// styles
import { GlobalStyle } from './globalStyle/GlobalStyle.style';
import { StyledApp } from './dash.style';

// components
import { RoutingWrapper } from './shells/main/routing/routingWrapper';
import { Bootstrapper } from './shells/main/components/bootstrapper/Bootstrapper';

// utils
import { getInitialMiddleware } from './shells/main/store/initialMiddleware';
import { getRemainingMiddleware } from './shells/main/store/remainingMiddleware';
import { getABPencilToggle } from './shells/main/utils/setABPencilToggle';

import { globalInitialReducers, scopedInitialReducers } from './shells/main/reducers/initialReducers';
import { getAuthConfig } from './auth';

// we don't have an initial state - it's provided by the init response
const state = {};
const allMiddleware = getInitialMiddleware().concat(getRemainingMiddleware());

// This call will result in fetch and axios network calls being intercept to inject auth headers:
setupAuthNetworkIntercepts(MANAGER_VIEW_APP_PREFIX);
const container = document.getElementById('root');

const includeCx = !offerSessionUtils.hasDashCssOverrideValue('no-cx');

const BaseCxComponent: React.FC<PropsWithChildren> = (props) =>
    includeCx ? <CxThemeProvider themeName={'Cx'}>{props.children}</CxThemeProvider> : <>{props.children}</>;

const startReactApp = (store: Store) => {
    createRoot(container!).render(
        <>
            <GlobalStyle />

            <BaseCxComponent>
                <InterstateThemeProvider
                    themeName="Interstate"
                    applicationName="Manager View"
                    scopeName="Manager View"
                    themeRegistries={[interstateThemeRegistry]}
                >
                    <InterstateBetaThemeProvider
                        themeName="Interstate"
                        applicationName="Manager View"
                        scopeName="Manager View"
                        themeRegistries={[interstateBetaThemeRegistry]}
                    >
                        <ReduxProvider store={store}>
                            <ScopedStateProvider primary>
                                <ConnectedRouter history={historyInstance}>
                                    <AuthManager
                                        appPrefix={MANAGER_VIEW_APP_PREFIX}
                                        offerReadyContent={(props) => <ReadyContent {...props} loadingPage={LoadingPage} />}
                                        getConfig={getAuthConfig}
                                    >
                                        <Bootstrapper>
                                            <StyledApp>
                                                <RoutingWrapper />
                                            </StyledApp>
                                        </Bootstrapper>
                                    </AuthManager>
                                </ConnectedRouter>
                            </ScopedStateProvider>
                        </ReduxProvider>
                    </InterstateBetaThemeProvider>
                </InterstateThemeProvider>
            </BaseCxComponent>
        </>
    );
};

const startApp = async () => {
    const store = configureStore(state, allMiddleware, globalInitialReducers, scopedInitialReducers, getABPencilToggle());
    startReactApp(store);
};

startApp();
