// externals
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';

// libraries
import type { FeatureToggles } from '@makemydeal/dr-platform-shared';
import { FeatureToggles as AnyFeatureToggles } from '@makemydeal/dr-feature-toggles-client';

// consts/enums
import { INIT_PENCIL_SUCCESS } from '../actionTypes/initActionTypes';

// interfaces/types
import type { BootstrapAppInitSuccessAction, AllFeatureToggleActions } from '../actions/bootstrapSharedActionCreators';
import type { FeatureTogglesAndUrlOnly } from '../types/featureToggleTypes';

export type IFeatureToggleState = FeatureToggles;

export const getFeatureTogglesInitSuccessPayload = (action: BootstrapAppInitSuccessAction): Partial<FeatureToggles> => {
    return action.payload?.featureToggles || ({} as Partial<FeatureToggles>);
};

export const getFeatureToggleFromSlice = (
    featureToggleState: IFeatureToggleState,
    name: keyof FeatureTogglesAndUrlOnly
): boolean => {
    return (featureToggleState as AnyFeatureToggles)[name as string] || false;
};

export const isIncentivesActivityEnabledFromSlice = (featureToggleState: IFeatureToggleState): boolean =>
    getFeatureToggleFromSlice(featureToggleState, 'dashUseIncentivesActivity');

export const isMenuActivityEnabledFromSlice = (featureToggleState: IFeatureToggleState): boolean =>
    getFeatureToggleFromSlice(featureToggleState, 'dashUseMenuActivity');

export const isTradeActivityEnabledFromSlice = (featureToggleState: IFeatureToggleState): boolean =>
    getFeatureToggleFromSlice(featureToggleState, 'dashUseTradeActivity');

export const isAccessoriesCatalogEnabledFromSlice = (featureToggleState: IFeatureToggleState): boolean =>
    getFeatureToggleFromSlice(featureToggleState, 'dashUseAccessoriesCatalogActivity');

export const isDigitalDealJacketEnabledFromSlice = (featureToggleState: IFeatureToggleState): boolean =>
    getFeatureToggleFromSlice(featureToggleState, 'enableDigitalDealJacket');

export const reducer: Reducer<IFeatureToggleState> = (state: IFeatureToggleState = {}, action: AllFeatureToggleActions) => {
    if (!action) {
        return state;
    }
    return produce(state, (draft: Draft<IFeatureToggleState>) => {
        const draftToUse = draft as Draft<AnyFeatureToggles>;
        switch (action.type) {
            case INIT_PENCIL_SUCCESS: {
                const featureToggleNames = Object.keys(getFeatureTogglesInitSuccessPayload(action));
                featureToggleNames.forEach((name) => {
                    draftToUse[name] = (action.payload!.featureToggles as AnyFeatureToggles)[name];
                });
                return;
            }
        }
    });
};
