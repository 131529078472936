import { Badge } from '@interstate-beta/components/Badge';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const OfferCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .offer-card-container {
        box-sizing: border-box;
        border: 1px solid #cacfd9;
        border-radius: 0.3rem;
        background-color: #ffffff;
        box-shadow: 0 0.145rem 0.3rem 0 rgba(0, 0, 0, 0.05);
        width: 19.3rem;
        margin-bottom: 1.8rem;
        position: relative;

        @media (max-width: ${BreakPoint.TABLET_PORTRAIT}) {
            width: 16.8rem;
            height: 16.4rem;
            margin: 0 auto;
        }

        .panel-body {
            flex-direction: column;
        }
        .card-media-header-content-container {
            height: 75%;
        }
        .offer-card-compare-check-box {
            padding: 0.8rem 0 1rem 1.8rem;
        }
    }

    @media (max-width: ${BreakPoint.SMALL}) {
        margin: 0 auto 1.5rem;
    }
`;

export const OfferBadge = styled(Badge)`
    position: absolute;
    right: 1rem;
`;
