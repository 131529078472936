import { FlexCol, FlexGridCol, FlexRow } from '@makemydeal/dr-dash-components';

import { Typography } from '@interstate/components/Typography';

import { ActionButton } from './ActionButton';
import {
    LIENHOLDER_FORM_HEADER_ADD_LABEL,
    LIENHOLDER_FORM_HEADER_EDIT_LABEL,
    LIENHOLDER_FORM_HEADER_TITLE,
    LienholderFormHeaderControlIds
} from './constants';

export interface LienholderFormHeaderProps {
    isEditMode: boolean;
    isLienholderInfoAvailable: boolean;
    toggleEditMode: (mode: boolean) => void;
}

const LienholderFormHeader = ({ isEditMode, isLienholderInfoAvailable, toggleEditMode }: LienholderFormHeaderProps) => (
    <FlexGridCol data-testid={LienholderFormHeaderControlIds.Header}>
        <FlexCol>
            <FlexRow justifyContent="space-between" alignItems="center">
                {(isLienholderInfoAvailable || isEditMode) && (
                    <>
                        <Typography variant="h6">{LIENHOLDER_FORM_HEADER_TITLE}</Typography>
                        {!isEditMode && (
                            <ActionButton
                                label={LIENHOLDER_FORM_HEADER_EDIT_LABEL}
                                dataTestId={LienholderFormHeaderControlIds.ActionButtonEdit}
                                handleClick={() => toggleEditMode(true)}
                            />
                        )}
                    </>
                )}
                {!isLienholderInfoAvailable && !isEditMode && (
                    <ActionButton
                        label={LIENHOLDER_FORM_HEADER_ADD_LABEL}
                        dataTestId={LienholderFormHeaderControlIds.ActionButtonAdd}
                        handleClick={() => toggleEditMode(true)}
                    />
                )}
            </FlexRow>
        </FlexCol>
    </FlexGridCol>
);

export default LienholderFormHeader;
