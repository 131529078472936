// externals
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Grid } from '@interstate/components/Grid';
import { Button } from '@interstate/components/Button';
import { tradeInActionCreators, tradeInSelectors } from '@makemydeal/dr-dash-store';

// components
import TradeVinDecode from '../TradeVinDecode';
import { TradeDetailsAccordion } from '../TradeDetailsAccordion.interstate';
import { CheckBox, CheckBoxEventValue } from '@interstate/components/CheckBox';
import { InterstateOnChangeCallback, InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { BodyStyleField } from '../vehicle/BodyStyleField.interstate';
import { DoorsField } from '../vehicle/DoorsField.interstate';
import { InteriorColorField } from '../vehicle/InteriorColorField.interstate';
import { EngineField } from '../vehicle/EngineField.interstate';
import { TransmissionField } from '../vehicle/TransmissionField.interstate';
import { DriveLineField } from '../vehicle/DriveLineField.interstate';
import YearsField from '../vehicle/YearsField.interstate';
import MakesField from '../vehicle/MakesField.interstate';
import ModelsField from '../vehicle/ModelsField.interstate';
import TrimsField from '../vehicle/TrimsField.interstate';
import TrimDecodedField from '../vehicle/TrimDecodedField.interstate';
import OdometerField from '../vehicle/OdometerField.interstate';
import ExteriorColorField from '../vehicle/ExteriorColorField.interstate';
import VehicleConditionField from '../vehicle/VehicleConditionField.interstate';

// constants
import { LEASE, LEASE_TRADE, PURCHASE } from '../../../constants';

// styles
import { ButtonContainer } from '../trade.interstate.style';

// hooks
import { useScrollIntoView } from '../../../utils/useScrollIntoView';

import { hasEmptyField } from './utils';

interface ManualTradeInProps {
    cancel: () => void;
    save: () => void;
    isManualFormOpen: boolean;
    hasTradeIn: boolean;
}

const ManualTradeIn: React.FC<ManualTradeInProps> = ({ save, cancel, isManualFormOpen, hasTradeIn }) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    const ownershipType = useSelector(tradeInSelectors.getTradeOwnershipType);
    const vehicleMileage = useSelector(tradeInSelectors.getTradeMileage);
    const vehicleYear = useSelector(tradeInSelectors.getTradeYear);
    const vehicleMake = useSelector(tradeInSelectors.getTradeMake);
    const vehicleModel = useSelector(tradeInSelectors.getTradeModel);
    const vehicleTrim = useSelector(tradeInSelectors.getTradeTrim);
    const vinHasBeenDecoded = useSelector(tradeInSelectors.vinHasBeenDecoded);

    const areRequiredFieldsEmpty = hasEmptyField({
        vehicleMake,
        vehicleMileage,
        vehicleModel,
        vehicleTrim,
        vehicleYear
    });

    const updateOwnershipType: InterstateOnChangeCallback<CheckBoxEventValue> = (
        event: InterstateOnChangeEvent<CheckBoxEventValue>
    ) => {
        /* istanbul ignore next */
        const changeOwnershipType = event.target.value?.checkboxValue === LEASE ? PURCHASE : LEASE;
        dispatch(tradeInActionCreators.updateTradeInOwnershipType(changeOwnershipType));
    };

    useScrollIntoView(elementRef, isManualFormOpen);

    return (
        <div ref={elementRef}>
            <Grid container spacing="16px" sx={{ marginBottom: '8px' }}>
                {isManualFormOpen && (
                    <Grid xs={6} md={8}>
                        <TradeVinDecode isManualFormOpen={isManualFormOpen} />
                    </Grid>
                )}
                <Grid xs={6} md={4}>
                    <OdometerField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                </Grid>

                {isManualFormOpen && (
                    <>
                        <Grid xs={6} md={4}>
                            <YearsField isManualFormOpen={isManualFormOpen} />
                        </Grid>
                        <Grid xs={6} md={4}>
                            <MakesField isManualFormOpen={isManualFormOpen} />
                        </Grid>
                        <Grid xs={6} md={4}>
                            <ModelsField isManualFormOpen={isManualFormOpen} />
                        </Grid>
                        <Grid xs={6} md={4}>
                            {vinHasBeenDecoded ? (
                                <TrimDecodedField isManualFormOpen={isManualFormOpen} />
                            ) : (
                                <TrimsField isManualFormOpen={isManualFormOpen} />
                            )}
                        </Grid>
                    </>
                )}
                <Grid xs={6} md={4}>
                    <ExteriorColorField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                </Grid>
                <Grid xs={6} md={4}>
                    <VehicleConditionField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                </Grid>
                {isManualFormOpen && (
                    <Grid xs={12}>
                        <CheckBox
                            checked={ownershipType === LEASE ? true : false}
                            label={LEASE_TRADE}
                            onChange={updateOwnershipType}
                            value={ownershipType}
                        />
                    </Grid>
                )}
            </Grid>
            <TradeDetailsAccordion isManualFormOpen={isManualFormOpen}>
                <Grid container spacing="16px" sx={{ marginBottom: '8px' }}>
                    <Grid xs={6} md={4}>
                        <EngineField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                    </Grid>
                    <Grid xs={6} md={4}>
                        <TransmissionField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                    </Grid>
                    <Grid xs={6} md={4}>
                        <DriveLineField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                    </Grid>
                    <Grid xs={6} md={4}>
                        <BodyStyleField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                    </Grid>
                    <Grid xs={6} md={4}>
                        <DoorsField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                    </Grid>
                    <Grid xs={6} md={4}>
                        <InteriorColorField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                    </Grid>
                </Grid>
            </TradeDetailsAccordion>
            {isManualFormOpen && (
                <ButtonContainer data-testid="trade-details-manual-entry-btn">
                    <Button
                        id="btn-cancel-manual-trade-entry"
                        data-testid="btn-cancel-manual-trade-entry"
                        buttonStyle="secondary"
                        onClick={cancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="btn-save-manual-trade-entry"
                        data-testid="btn-save-manual-trade-entry"
                        buttonStyle="primary"
                        onClick={() => save()}
                        sx={{ marginLeft: '16px' }}
                        disabled={areRequiredFieldsEmpty}
                    >
                        Save
                    </Button>
                </ButtonContainer>
            )}
        </div>
    );
};

export default ManualTradeIn;
