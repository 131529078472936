import { Grid } from '@interstate-beta/components/Grid';
import { styledComponentsFunctions } from '@makemydeal/dr-dash-ui-utils';

export type MediaContainerProps = {
    colorToken: ColorTokenProps;
};

export type ColorTokenProps = {
    colorComplete: string;
    colorNotComplete: string;
    borderBottomNotComplete: string;
    backgroundColorComplete: string;
};

export const StyledCardMediaContainerNotCompleted = styledComponentsFunctions.styledComponentWithExcludedProps(Grid, [
    'colorToken'
])<MediaContainerProps>`
    background-color: transparent;
    color: ${({ colorToken }) => colorToken.colorNotComplete};
    border-bottom: 1px solid ${({ colorToken }) => colorToken.borderBottomNotComplete};
    padding: 4px 0;
`;

export const StyledCardMediaContainerCompleted = styledComponentsFunctions.styledComponentWithExcludedProps(Grid, [
    'colorToken'
])<MediaContainerProps>`
    background-color: ${({ colorToken }) => colorToken.backgroundColorComplete};
    color: ${({ colorToken }) => colorToken.colorComplete};
    padding: 4px 0;
`;
