import React from 'react';
import { Route } from 'react-router-dom';

import { ICreditBureauActivity } from '../types/ICreditBureauActivity';
import { getAllRoutes } from '../utils/routes';

import CreditBureauActivity from './CreditBureauActivity';
import { ScopedStateProvider } from '@makemydeal/dr-shared-store';

const Root = (props: ICreditBureauActivity) => {
    const { root } = getAllRoutes(props.baseRoute);

    return (
        <div
            style={{ maxWidth: '1456px', margin: '0 auto', padding: '0 16px' }}
            data-testid="activities-credit-bureau-container"
            className="activities-credit-bureau"
        >
            <ScopedStateProvider navigationScope>
                <Route path={root} component={CreditBureauActivity} />
            </ScopedStateProvider>
        </div>
    );
};

Root.displayName = 'CreditBureauActivity';

export default Root;
