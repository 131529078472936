import React from 'react';
import { LoadingIndicatorWrapper, StyledGridItem } from './styled.components';
import { PriceInput } from '@interstate/components/PriceInput';
import { Typography } from '@interstate/components/Typography';
import { Spinner as LoadingIndicator } from '@interstate/components/Spinner';

interface GridRowProps {
    data: Array<{
        sellRate: number;
        id: string;
        netCashDown: number;
        dueAtSigning: number;
        downPayment: number;
        monthlyPayment: number;
        isSelected: boolean;
        isLoading?: boolean;
    }>;
}
const maxValue = 9999999;

export const GridRow: React.FC<GridRowProps> = ({ data }) => {
    return (
        <>
            <StyledGridItem xs={3} padding="0 20px" maxHeight="70px">
                <PriceInput
                    autoInsertCommas
                    allowNegative={false}
                    id={'grid-term-input'}
                    data-testid={'grid-term-input'}
                    maxValue={maxValue}
                    allowDecimal={true}
                    decimalMaxLength={2}
                    name={'term-input'}
                    value="99"
                />
            </StyledGridItem>
            {data.map((item) => (
                <StyledGridItem xs={3} key={item.id} maxHeight="70px" alignItems="center">
                    {item.isLoading ? (
                        <LoadingIndicatorWrapper>
                            <LoadingIndicator data-testid="loading-spinner-terms-col" />
                        </LoadingIndicatorWrapper>
                    ) : (
                        <>
                            <Typography variant="body-lg" sx={{ fontSize: '12px' }}>
                                Boa
                            </Typography>
                            <Typography variant="body-lg" sx={{ fontSize: '16px' }}>
                                ${item.netCashDown}
                            </Typography>
                            <Typography variant="body-lg" sx={{ fontSize: '12px' }}>
                                {item.sellRate}
                            </Typography>
                        </>
                    )}
                </StyledGridItem>
            ))}
        </>
    );
};
