import { Typography } from '@interstate-beta/components/Typography';
import { FC } from 'react';

export const Label: FC<{ label: string; required: boolean; 'data-testid'?: string }> = ({ label, required, ...props }) => (
    <Typography variant="body-sm" className="label">
        <span data-testid={props['data-testid']}>
            {label}
            {required && <span style={{ color: 'red', whiteSpace: 'nowrap' }}> *</span>}
        </span>
    </Typography>
);
