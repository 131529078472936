import { FC } from 'react';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import IconInfoOutline from '@cx/ui/Icons/IconInfoOutline';

import {
    IncentiveTotalsContainer,
    IncentiveCurrentTotal,
    IncentiveTotalsRow,
    IncentiveTotalsColumn
} from './RebatesTotals.interstate.styles';
import { RebatesTotalsProps } from './RebatesTotalsProps';
import { Typography } from '@interstate/components/Typography';

export const RebatesTotals: FC<RebatesTotalsProps> = ({
    dealerCashTotal = 0,
    currentEditedIncentivesTotal = 0,
    currentAppliedIncentiveTotal = 0,
    showEditTotals = false,
    showRebatesTotal = false
}) => {
    return (
        <IncentiveTotalsContainer>
            <IncentiveTotalsColumn>
                {showEditTotals && (
                    <>
                        <IncentiveTotalsRow>
                            <Typography variant="body-sm">Dealer Cash Total</Typography>
                            <Typography variant="strong-sm" data-testid="incentives-dealer-cash-total">
                                {formatDollarsAndCents(dealerCashTotal)}
                            </Typography>
                        </IncentiveTotalsRow>
                        <IncentiveTotalsRow>
                            <Typography variant="body-sm">Incentives Total</Typography>
                            <Typography variant="strong-sm" data-testid="incentives-total">
                                {formatDollarsAndCents(currentEditedIncentivesTotal)}
                            </Typography>
                        </IncentiveTotalsRow>
                    </>
                )}
                {showRebatesTotal && (
                    <IncentiveTotalsRow>
                        <Typography variant="body-xs" sx={{ alignItems: 'center', display: 'flex' }}>
                            Current total
                            <IconInfoOutline />
                        </Typography>
                        <Typography variant="strong-xs" data-testid="incentives-current-total">
                            {formatDollarsAndCents(currentAppliedIncentiveTotal)}
                        </Typography>
                    </IncentiveTotalsRow>
                )}
            </IncentiveTotalsColumn>
        </IncentiveTotalsContainer>
    );
};
