import { RealState, ScopedState } from '../types/scopeState';

/**
 * Get's the ID of the primary scope
 * Can only be used when inside a RawStateProvider
 */
export const getPrimaryScopeId = (state: RealState) => state.__primaryScope;

/**
 * Get's the map of scopes, indexed by their IDs.
 * Can only be used when inside a RawStateProvider
 */
export const getScopes = (state: RealState) => state.__scopeMap.scopes;

/**
 * Gets an array of scope IDs representing the order of creation.
 * Can only be used when inside a RawStateProvider
 */
export const getScopesOrder = (state: RealState) => state.__scopeMap.order;

/**
 * Gets the ID of the current scope
 * Can only be used when inside a ScopedStateProvider
 */
export const getCurrentScopeId = (state: ScopedState) => state.__scopeId;

/**
 * Checks if the current store has scopes enabled.
 * Can be used on RealState and on Scoped State (ScopedStateProvider and RawStateProvider).
 */
export const getIsScopedStoreEnabled = (state: any) => Boolean(state.__isScoped);
