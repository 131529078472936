/**
 * PURPOSE: Co-ordinates post-bootstrap initialization.  This is needed because feature toggles and other data are not available
 *   when the app first loads.
 */
import OktaAuth, { AccessToken, IDToken, OktaAuthOptions, TokenManagerInterface, UserClaims } from '@okta/okta-auth-js';

// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { bootstrapActionTypes, bridgeUserActionTypes, featureToggleSelectors, initActionTypes } from '@makemydeal/dr-shared-store';
import { DashMiddleware, DashNext, DashStore } from '@makemydeal/dr-shared-store';
import {
    bridgeUserSelectors,
    configSelectors,
    offerReduxSelectors,
    vehicleActionCreators,
    vehicleSelectors,
    vehicleProtectionActionTypes
} from '@makemydeal/dr-dash-store';
import { BridgeUser } from '@makemydeal/dr-dash-types';

// config
import { initDrGtm } from '@makemydeal/dr-google-analytics-utils';
import {
    locHrefUtil,
    hostUrlHelper,
    HostEnvironment,
    environmentNameUtils,
    MANAGER_VIEW_APP_PREFIX
} from '@makemydeal/dr-shared-ui-utils';

const APPLICATION_NAME = 'Manager View';
const DATA_LAYER_NAME = 'dataLayer';
const GTM_DATA_READY = 'GTM_DATA_READY';
const DOMAINS = ['@coxautoinc.com', '@coxinc.com'];

async function getUserSub(authClient: OktaAuth): Promise<string | undefined> {
    try {
        const tokenManager: TokenManagerInterface = authClient.tokenManager;
        const tokens = await authClient.tokenManager.getTokens();

        if (tokens) {
            const accessToken: AccessToken = (await tokenManager.get('accessToken')) as AccessToken;

            if (accessToken?.claims) {
                return accessToken.claims.sub;
            }
        }
    } catch (err) {
        console.error('Error getting user sub:', err);
    }

    return undefined;
}

async function getUserEmail(authClient: OktaAuth): Promise<string | undefined> {
    try {
        const tokenManager: TokenManagerInterface = authClient.tokenManager;
        const accessToken: AccessToken = (await tokenManager.get('accessToken')) as AccessToken;
        const idToken: IDToken = (await tokenManager.get('idToken')) as IDToken;
        const userInfo: UserClaims = await authClient.token.getUserInfo(accessToken, idToken);

        return userInfo?.email;
    } catch (error) {
        console.error('Error getting user data:', error);
    }
    return undefined;
}

const initializePendo = (bridgeUser: BridgeUser) => {
    try {
        window.pendo.initialize({
            visitor: {
                id: bridgeUser.bridgeId,
                full_name: bridgeUser.fullName
            },
            account: {
                id: bridgeUser.accountId ? `${bridgeUser.accountId}` : 'dc_manager_view'
            }
        });
    } catch (error) {
        console.error('Failed to initialize Pendo:', error);
    }
};

export const postBootstrapMiddleware: DashMiddleware = (store: DashStore) => (next: DashNext) => async (action: AnyFSA) => {
    // need reducers to execute first so that we can use the state that's updated by INIT_PENCIL_SUCCESS
    const nextResult = next(action);
    let state;
    const config: OktaAuthOptions = {
        issuer: 'https://authorize.coxautoinc.com/oauth2/aus132uaxy2eomhmi357'
    };

    switch (action.type) {
        case bridgeUserActionTypes.BRIDGE_USER_SUCCESS:
            state = store.getState();
            const gtmId = configSelectors.getGtmId(state);

            const isPendoEnabled = featureToggleSelectors.enablePendoInMV(state);
            const enableCommonOrgIdForPendo = featureToggleSelectors.enableCommonOrgIdForPendo(state);

            if (isPendoEnabled) {
                const bridgeId = bridgeUserSelectors.getBridgeId(state);
                const fullName = bridgeUserSelectors.getFullName(state);
                const accountId = offerReduxSelectors.getCommonOrgId(state);
                if (enableCommonOrgIdForPendo) initializePendo({ bridgeId, fullName, accountId });
                else initializePendo({ bridgeId, fullName });
            }

            if (gtmId.length) {
                const environmentEnum = hostUrlHelper.getHostUrlEnvironment(MANAGER_VIEW_APP_PREFIX, locHrefUtil.getLocHref());
                const environmentString = environmentNameUtils.getEnvironmentName(environmentEnum);
                const isGtmDataLayerV2Enabled = featureToggleSelectors.isGtmDataLayerV2Enabled(state);
                let isUserInternal = undefined;

                if (isGtmDataLayerV2Enabled) {
                    const authClient: OktaAuth = new OktaAuth(config);
                    const bridgePlatformId = await getUserSub(authClient);
                    const userEmail = await getUserEmail(authClient);

                    if (userEmail) {
                        isUserInternal = DOMAINS.some((domain) => domain.includes(userEmail.split('@')[1])) || undefined;
                    }

                    initDrGtm({
                        gtmId,
                        dataLayerName: DATA_LAYER_NAME,
                        environment: environmentString,
                        applicationName: APPLICATION_NAME,
                        isUserInternal,
                        isProduction: environmentEnum === HostEnvironment.Prod,
                        userType: 'dealer',
                        version: 2,
                        bridgeUsername: bridgeUserSelectors.getBridgeUsername(state),
                        commonOrgId: offerReduxSelectors.getCommonOrgId(state),
                        bridgePlatformId: bridgePlatformId
                    });

                    store.dispatch({ type: GTM_DATA_READY });
                } else {
                    initDrGtm({
                        gtmId,
                        dataLayerName: DATA_LAYER_NAME,
                        environment: environmentString,
                        applicationName: APPLICATION_NAME,
                        isUserInternal,
                        isProduction: environmentEnum === HostEnvironment.Prod,
                        userType: 'dealer'
                    });
                    store.dispatch({ type: GTM_DATA_READY });
                }
            }

            break;

        case bootstrapActionTypes.BOOTSTRAP_SUCCESS:
            store.dispatch({ type: vehicleProtectionActionTypes.DASH_MENU_SKIPPED });

            break;
        case initActionTypes.INIT_SUCCESS:
            state = store.getState();

            const orgId = offerReduxSelectors.getCommonOrgId(state);

            if (!orgId) return;

            const vin = vehicleSelectors.getVehicleVin(state);
            const stockNumber = vehicleSelectors.getVehicleStockNumber(state);

            const query = vin || stockNumber;

            if (query) {
                const bffEndpoint = configSelectors.getServicesBff(state);
                store.dispatch(vehicleActionCreators.fetchCviVehiclesResults(query, orgId, bffEndpoint));
            }

            break;
    }

    return nextResult;
};
