// externals
import { FC, useState, PropsWithChildren, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { nanoid } from 'nanoid';

// libraries
import {
    accessoriesActionCreators,
    accessoriesActionTypes,
    accessoriesSelectors,
    deskingSelectors,
    paymentSelectors,
    useAccessoryHooks
} from '@makemydeal/dr-dash-store';
import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';
import { Alert } from '@interstate/components/Alert';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

// components
import { AccessoryRowForm } from './AccessoryRowForm.interstate';
import { AccessoriesSummary } from './AccessoriesSummary.interstate';
import { ManualAccessoryControlButtons } from './ManualAccessoryControlButtons.interstate';

// styles
import {
    AccessoriesTotalRow,
    AddAccessoryActionContainer,
    ManualAccessoriesErrorContainer,
    ManualAccessoriesFormContainer
} from '../../ManualAccessories.interstate.styles';

// interfaces/types
import type { Accessory } from '@makemydeal/dr-dash-types';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { LEASE } from '@makemydeal/dr-platform-types';

const { UPDATED_OFFER_MENU_PRODUCTS } = accessoriesActionTypes;

// mocking accessories until they are available from state
const getInitAccessoryData = (): Accessory => ({
    code: '',
    name: '',
    amount: 0,
    cost: 0,
    deleted: false,
    originalAmount: 0,
    profit: 'frontEndProfit',
    residual: 0,
    residualType: 'Dollar',
    upFront: false,
    weOwe: false,
    isManualAccessory: true
});

export const AccessoriesForm: FC<PropsWithChildren> = () => {
    const dispatch = useDispatch();
    const stateAccessories = useSelector(accessoriesSelectors.getAccessoriesList);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const {
        hasChanges,
        setHasChanges,
        accessories,
        addAccessory,
        updateAccessory,
        setAccessories,
        updateAccessoryValidity,
        hasValidationErrors,
        hasVisibleValidationErrors
    } = useAccessoryHooks.useAccessoryActions(stateAccessories);
    const [haveAccessoriesAdded, setHaveAccessoriesAdded] = useState(false);
    const isPaymentError = useSelector(paymentSelectors.getIsError);
    const failedAccessories = useSelector(deskingSelectors.wasTheLastFailure(UPDATED_OFFER_MENU_PRODUCTS));

    const theme = useInterstateTheme();
    const shouldShowPaymentAlert = haveAccessoriesAdded && isPaymentError && failedAccessories;
    const shouldShowAlertContainer = shouldShowPaymentAlert || hasVisibleValidationErrors;

    useEffect(() => {
        if (!accessories.length) {
            addAccessory(getInitAccessoryData());
        }
    }, [accessories, addAccessory]);

    const handleRemoveAccessory = (index: number) => {
        updateAccessory(index, 'deleted', true);
    };

    const handleRestoreAccessory = (index: number) => {
        updateAccessory(index, 'deleted', false);
    };

    const handleSave = () => {
        accessories.forEach((acc, index) => {
            if (!isEqual(acc, stateAccessories[index])) {
                acc.isManualAccessory = true;
                // This id is too keep track of manualAccessories removal process from the catalog iframe
                acc.manualAccessoryId = nanoid();
            }
        });
        const accessoriesUpdated = [...accessories].filter((acc) => !acc.deleted);
        setAccessories(accessoriesUpdated);
        setHaveAccessoriesAdded(true);
        setHasChanges(false);
        dispatch(accessoriesActionCreators.openForm({ scrollIntoView: true }));
        dispatch(accessoriesActionCreators.addManualAccessories(accessoriesUpdated));
    };

    const closeHandler = () => {
        const accessoriesUpdated = [...accessories].filter((acc) => !acc.deleted);
        setAccessories(accessoriesUpdated);
        dispatch(accessoriesActionCreators.closeForm());
    };

    const formTotalAmount = useMemo(() => {
        return accessories.reduce((sum, accessory) => sum + (accessory.deleted ? 0 : accessory.amount), 0);
    }, [accessories]);

    return (
        <>
            <ManualAccessoriesErrorContainer data-testid="manual-accessories-error-container" opened={shouldShowAlertContainer}>
                {hasVisibleValidationErrors && (
                    <Alert
                        data-testid={'manual-accessories-validation-error'}
                        role="manual-accessories-validation-error"
                        type={'error'}
                    >
                        <Typography tag="span" variant="body-sm" data-testid="manual-accessories-validation-error-message">
                            One or more accessory error(s). Correct error(s) and save your entries again.
                        </Typography>
                    </Alert>
                )}
                {shouldShowPaymentAlert && !hasVisibleValidationErrors && (
                    <Alert data-testid={'manual-accessories-payment-error'} role="manual-accessories-payment-error" type={'error'}>
                        <Typography tag="span" variant="body-sm" data-testid="manual-accessories-payment-error-message">
                            Unknown accessories error(s). Check and save your entries again.
                        </Typography>
                    </Alert>
                )}
            </ManualAccessoriesErrorContainer>
            {accessories.map((accessory, index: number) => (
                <ManualAccessoriesFormContainer
                    id="manual-accessories-form-container"
                    data-testid="manual-accessories-form-container"
                    key={`manual-accessories-form-container-${index}`}
                    theme={theme}
                    className={index === 0 ? 'first-accessory-form' : ''}
                >
                    <AccessoryRowForm
                        index={index}
                        accessory={accessory}
                        updateAccessory={updateAccessory}
                        updateAccessoryValidity={updateAccessoryValidity}
                        removeAccessory={handleRemoveAccessory}
                        restoreAccessory={handleRestoreAccessory}
                        isLeaseLayout={offerType === LEASE}
                    />
                </ManualAccessoriesFormContainer>
            ))}

            <AddAccessoryActionContainer theme={theme}>
                <Action
                    data-testid="add-accessory-button"
                    onClick={() => {
                        setHaveAccessoriesAdded(false);
                        addAccessory(getInitAccessoryData());
                    }}
                >
                    <Typography data-testid="add-accessory" variant="anchor-block-sm" color="base.color.blue.700">
                        {'+  Add Accessory'}
                    </Typography>
                </Action>
            </AddAccessoryActionContainer>
            <AccessoriesTotalRow>
                <AccessoriesSummary totalAmount={formTotalAmount} />
            </AccessoriesTotalRow>
            <ManualAccessoryControlButtons
                handleClose={closeHandler}
                handleSave={handleSave}
                isDisabled={!hasChanges || hasValidationErrors}
            />
        </>
    );
};
