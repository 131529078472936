import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import {
    tradeInSelectors,
    tradeInComponentActionCreators,
    tradeInComponentSelectors,
    tradeInActionCreators
} from '@makemydeal/dr-dash-store';
import { updateVehicle } from '@makemydeal/dr-activities-tradein';
import { SelectInputOption } from '@interstate/components/SelectInput';
import { TextInput } from '@interstate/components/TextInput';

// components
import { SelectInput } from './TradeInSelectInput';

// constants
import { TRADE_IN_YEAR } from '@makemydeal/dr-dash-components';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

interface YearsFieldProps {
    isManualFormOpen: boolean;
}

const YearsField: React.FC<YearsFieldProps> = ({ isManualFormOpen }) => {
    const dispatch = useDispatch();
    const vehicleYear = useSelector(tradeInSelectors.getTradeYear);
    const tradeInVehicleYears = useSelector(tradeInComponentSelectors.getTradeInComponentYears);
    const tradeInVehicle = useSelector(tradeInComponentSelectors.getTradeInComponentVehicle);
    const vinHasBeenDecoded = useSelector(tradeInSelectors.vinHasBeenDecoded);
    const hasMultipleVin = useSelector(tradeInSelectors.hasMultipleVin);

    useEffect(() => {
        dispatch(tradeInComponentActionCreators.fetchTradeInComponentYears(MANAGER_VIEW_APP_PREFIX));
    }, [dispatch]);

    useEffect(() => {
        if (vehicleYear) {
            dispatch(tradeInComponentActionCreators.fetchTradeInComponentMakes(MANAGER_VIEW_APP_PREFIX, vehicleYear));
        }
    }, [vinHasBeenDecoded, dispatch, vehicleYear]);

    const getTradeInYears = useMemo(
        (): SelectInputOption[] => tradeInVehicleYears.options.map((year) => ({ label: year, value: year })).sort(),
        [tradeInVehicleYears.options]
    );

    const onYearChange = (event: any) => {
        const value = event.target.value;
        dispatch(tradeInActionCreators.updateTradeInYear(value));
        dispatch(
            updateVehicle({
                ...tradeInVehicle,
                year: value,
                make: { id: '', name: '' },
                model: { id: '', name: '' },
                trim: { id: '', name: '', vehicleId: '' }
            })
        );
        if (value) {
            dispatch(tradeInComponentActionCreators.fetchTradeInComponentMakes(MANAGER_VIEW_APP_PREFIX, value));
        }
    };

    const yearField = Number(vehicleYear) === 0 ? '' : vehicleYear;

    return (
        <>
            {isManualFormOpen && (
                <>
                    {vinHasBeenDecoded && !hasMultipleVin ? (
                        <TextInput
                            data-testid="year-input"
                            label={TRADE_IN_YEAR}
                            name={TRADE_IN_YEAR}
                            value={yearField}
                            onChange={onYearChange}
                            disabled
                        />
                    ) : (
                        <SelectInput
                            data-testid="year-select-input"
                            label={TRADE_IN_YEAR}
                            name={TRADE_IN_YEAR}
                            onChange={onYearChange}
                            options={getTradeInYears}
                            value={yearField}
                            placeholder="YYYY"
                            disabled={vinHasBeenDecoded || hasMultipleVin || tradeInVehicleYears.options.length === 0}
                            displayDeselectOption={false}
                            required
                        />
                    )}
                </>
            )}
        </>
    );
};

export default YearsField;
