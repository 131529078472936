import styled from 'styled-components';
import { BreakPoint } from '@makemydeal/dr-activities-common';

// 16px  ${({ theme }) => theme.tokens.BaseSpace40};

export const StyledVehicleInfoCardContainer = styled.div`
    max-width: ${BreakPoint.PHONE};
`;

export const StyledVehicleInfoCard = styled.div`
    display: flex;
    align-items: start;
    gap: ${({ theme }) => theme.tokens.BaseSpace40};
`;

export const StyledVehicleInfoCardImage = styled.div`
    flex-shrink: 0;

    img {
        width: 9.5rem;
        max-height: 5.25rem;
        object-fit: cover;
        border-radius: 0.3rem;
    }
`;

export const StyledVehicleInfoCardContent = styled.div`
    button {
        span {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            text-transform: capitalize;
        }
    }
`;

export const StyledVehicleInfoCardStockVin = styled.div`
    margin-top: ${({ theme }) => theme.tokens.BaseSpace25};
    display: flex;
    flex-wrap: wrap;
    column-gap: ${({ theme }) => theme.tokens.BaseSpace50};
`;

export const StyledVehicleInfoCardActions = styled.div`
    margin-top: ${({ theme }) => theme.tokens.BaseSpace40};
    display: flex;
    align-items: center;
    padding: 0;
    gap: ${({ theme }) => theme.tokens.BaseSpace40};
    position: relative;
`;
