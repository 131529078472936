// externals
import { FC, useState, useEffect } from 'react';

// components
import { NumericInput } from '@interstate/components/NumericInput';
import { EditedTaxFieldInputContainer } from './EditedTaxField.style';

// actions
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { TaxDetail } from '../types';

interface RetailPriceFieldProps {
    data: TaxDetail;
    handleBlur: (e: InterstateOnChangeEvent<TextInputEventValue>, keyName: string) => void;
}

const TaxItemField: FC<RetailPriceFieldProps> = ({ data, handleBlur }: RetailPriceFieldProps) => {
    const [inputProp, setInputProp] = useState(data);
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        if (Number.isFinite(data.rate) && data.rate !== Number(inputProp.rate)) {
            setHasChanged(true);
        } else {
            setHasChanged(false);
        }
    }, [inputProp.rate, data.rate]);

    const isEdited = hasChanged || data.isEditedRate;

    const handleBlurWrapper = (e: InterstateOnChangeEvent<TextInputEventValue>) => {
        if (!inputProp.rate && !data.rate) {
            setInputProp({ ...inputProp, rate: '0' });
            return;
        }
        handleBlur(e, data.key);
    };
    return (
        <EditedTaxFieldInputContainer $isEdited={isEdited}>
            <NumericInput
                data-testid={`detailed-table-cell-input-${data.name}`}
                name={'detail-rate'}
                inputSuffix={'%'}
                sx={{ width: '105px' }}
                onChange={(e) => setInputProp({ ...inputProp, rate: e.target.value })}
                value={inputProp.rate}
                onBlur={handleBlurWrapper}
                allowDecimal={true}
                decimalMaxLength={4}
                maxValue={99.9999}
                placeholder={'0'}
                autoInsertCommas={true}
            />
        </EditedTaxFieldInputContainer>
    );
};

export default TaxItemField;
