// libraries
import type { DealHistory } from '@makemydeal/dr-dash-types';
import { vehicleSelectors, offerReduxSelectors } from '@makemydeal/dr-dash-store';

// components
import DealSummaryItem from '../common/DealSummaryItem';
import { Grid } from '@interstate/components/Grid';
import LineItemWithChildren from '../common/LineItemWithChildren';

// constants
import * as constants from '../../constants';

// hooks
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';

// utils
import { formatDollarsAndCents, formatNumber } from '@makemydeal/dr-common-utils';

export type TotalResidualProps = {
    deal?: DealHistory;
    useWrapper?: boolean;
    hasDelta?: boolean;
};

const TotalResidual = ({ deal }: TotalResidualProps) => {
    const state = useDealRootState(deal);

    const adjustedResidualAmount = offerReduxSelectors.getAdjustedResidualValue(state);

    const totalResidual = formatDollarsAndCents(adjustedResidualAmount); // showing dollar regardless of selected override type
    const baseResidualAmount = offerReduxSelectors.getBaseResidualDollar(state);
    const baseResidualPercent = offerReduxSelectors.getBaseResidualPercent(state);
    const milesPerYear = offerReduxSelectors.getAnnualMiles(state);
    const existingMileage = vehicleSelectors.getMileage(state);

    return (
        <DealSummaryItem label={constants.TOTAL_RESIDUAL} value={totalResidual}>
            <Grid>
                <LineItemWithChildren label={constants.BASE_RESIDUAL} value={formatDollarsAndCents(baseResidualAmount)} />
                <LineItemWithChildren label={constants.MILES_PER_YEAR} value={formatNumber(milesPerYear)} />
                <LineItemWithChildren label={constants.EXISTING_MILEAGE} value={formatNumber(existingMileage)} />
                <br />
                <LineItemWithChildren label={constants.BASE_RESIDUAL_PERCENT} value={`${baseResidualPercent}%`} />
            </Grid>
        </DealSummaryItem>
    );
};

export default TotalResidual;
