import { NumericInput } from '@interstate/components/NumericInput';
import { Typography } from '@interstate-beta/components/Typography';
import { GridItem } from './styled.components';

interface TermsMothItem {
    name: string;
    val: number;
}

const items: TermsMothItem[] = [
    { name: 'Terms(mo)', val: 48 },
    { name: 'Terms(mo)', val: 60 },
    { name: 'Terms(mo)', val: 72 }
];

export const TermsMoth: React.FC = () => {
    return (
        <>
            <GridItem
                xs={3}
                className="header"
                sx={{
                    background: '#f4f4f4'
                }}
            >
                <Typography data-testid="cash-down-header" variant="body-lg" sx={{ fontSize: '16px' }}>
                    CashDown
                </Typography>
            </GridItem>
            {items.map((item, index) => (
                <GridItem xs={3} key={index} padding="0 20px">
                    <NumericInput
                        sx={{ width: '100%' }}
                        placeholder="0"
                        name={item.name}
                        label={item.name}
                        value={item.val}
                        allowDecimal={false}
                        autoInsertCommas={true}
                        minValue={1}
                    />
                </GridItem>
            ))}
        </>
    );
};
