export enum PushToDmsStatus {
    Ready = 'ready',
    Sending = 'sending',
    Failure = 'failure',
    Ok = 'ok'
}

export type PushToDmsState = {
    cdmsResponseData?: CdmsResponseData;
    error?: PushToDmsError;
    transferToDMS?: string;
    responseStatusCode: number;
    dealXgId?: string;
    dealXgVersion?: string;
    pushToDmsWsEvent?: PushToDmsWsEvent;
    lastSucceedDmsDealId?: string;
    lastSucceedDmsTime?: Date;
    cdmsStorefrontErrorMessage?: string;
};

export type PushToDmsWsEvent = {
    _type: 'pushToDmsEvent';
    event_id?: string;
    event_identity_id?: string;
    event_name?: string;
    event_source?: string;
    event_transaction_id?: string;
    event_type?: string;
    payload?: PushToDmsWsEventPayload;
};

type PushToDmsWsEventPayload = {
    deal_id?: string;
    deal_version?: string;
    dms_deal_id?: string;
    dms_type?: string;
    message?: string;
    partner_dealer_id?: string;
    partner_id?: string;
};

export type PushToDmsError = {
    errorDetails?: string;
    statusCode: number;
};

export type CdmsResponseData = {
    message?: CdmsResponseMessageData;
    status?: string;
    action?: string;
    version?: string;
    dataEntryURL?: string;
    error?: string;
    internalUse?: {
        allInfoReceived?: boolean;
        processed?: boolean;
        putInQueue?: boolean;
    };
    deal?: string;
};

export type CdmsResponseMessageData = {
    message?: string;
    buyerId?: string;
    cobuyerId?: string;
    deal?: string;
    dmsDealId?: string;
    version?: string;
};
