import {
    creditAppSelectors,
    creditDecisionSelectors,
    dealXgDetailsSelectors,
    navigationActionCreators,
    offerActionCreators,
    offerInfoSelectors,
    shopperNotificationsSelectors
} from '@makemydeal/dr-dash-store';
import { SendDialogTypes, StateTree } from '@makemydeal/dr-dash-types';
import { ACTIVITY_NAME_CREDIT_APPLICATION } from '@makemydeal/dr-dpm-client';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomerUI } from '../../customerUI/useCustomerUI';
import SendToCustomerDialogInterstate from '../../sendToCustomerDialog/SendToCustomerDialog.interstate';
import DealActivityCard from '../DealActivityCard.interstate';
import { CreditAppCardContent } from './CreditAppCardContent';

const name = 'creditApp';

const CreditAppCard = () => {
    const [showSendToCustomerDialog, setShowSendToCustomerDialog] = React.useState(false);
    const state = useSelector((state: StateTree) => state);
    const completed = useSelector(creditAppSelectors.isCreditAppCompleted);
    const cardValue = useSelector(creditAppSelectors.getCreditAppCardLabel);
    const creditAppURL = useSelector(offerInfoSelectors.getCreditAppURL);
    const notifications = shopperNotificationsSelectors.getShopperNotificationsByActivity(state, 'creditApp');
    const creditAppDpmState = dealXgDetailsSelectors.getDpmStateByActivity(state, ACTIVITY_NAME_CREDIT_APPLICATION);
    const crmIntegrationToggle = useSelector(dealerSelectors.getCrmIntegrationToggle);
    const { showCustomerCard } = useCustomerUI();
    const dispatch = useDispatch();
    const enableCreditBureauAndDecisionPBCs = useSelector(featureToggleSelectors.enableCreditBureauAndDecisionPBCs);
    const isCreditProviderRouteOne: boolean = useSelector(dealerSelectors.isCreditProviderRouteOne);
    const lenderList = useSelector(creditDecisionSelectors.getLenderCreditDecisions);
    const hasDecisionAvailable = lenderList.length > 0;
    const navigateToCreditDecision = () => dispatch(navigationActionCreators.navigateToCreditDecision());
    const shouldRenderCreditAppCardContent = enableCreditBureauAndDecisionPBCs && !isCreditProviderRouteOne;
    const renderViewLinkText = enableCreditBureauAndDecisionPBCs && hasDecisionAvailable;

    // istanbul ignore next # this is invoked WAY down the pipeline and out of scope here. We just want to delay getting hte URL until the url loads asynchronously
    const urlSelector = (state: any) => {
        const url = offerInfoSelectors.getCreditAppURL(state);
        return `\n${url}`;
    };

    const sendToCustomerAction = () => {
        dispatch(offerActionCreators.saveOffer());
        if (crmIntegrationToggle) {
            showCustomerCard(true, { tab: 'inbox', messageContentSelector: urlSelector, waitForOfferSave: true });
        } else {
            setShowSendToCustomerDialog(true);
        }
    };

    const handleSendToCustomerHide = () => {
        setShowSendToCustomerDialog(false);
    };

    const linkText = notifications.length ? 'Resend Link' : 'Send Link';

    return (
        <div>
            <DealActivityCard
                showCardAnchor={shouldRenderCreditAppCardContent && hasDecisionAvailable}
                dpmState={creditAppDpmState}
                name={name}
                cardValue={cardValue}
                completed={completed}
                action={enableCreditBureauAndDecisionPBCs ? navigateToCreditDecision : sendToCustomerAction}
                htmlId="creditApp-link"
                data-testid="creditApp-link"
                linkText={shouldRenderCreditAppCardContent && hasDecisionAvailable ? 'View' : linkText}
                additionalContent={shouldRenderCreditAppCardContent && <CreditAppCardContent />}
            />

            <SendToCustomerDialogInterstate
                shareUrl={creditAppURL}
                dialogHeader="Send Credit App to Customer"
                dialogPrompt="Send the customer a direct link to complete the credit application"
                data-testid="send-to-customer-dialog"
                show={showSendToCustomerDialog}
                onHide={handleSendToCustomerHide}
                dialogType={SendDialogTypes.CreditApp}
            />
        </div>
    );
};

export default CreditAppCard;
