import styled from 'styled-components';

export const LoadingIndicatorWrapper = styled.div`
    position: relative;

    & #interstitial-overlay-root {
        background-color: transparent;
    }
    & #ids-spinner-root {
        color: gray;
    }
    & #interstitial-content-root {
        color: gray;
    }
`;
