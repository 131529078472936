import { Grid } from '@interstate/components/Grid';
import { GridRow } from './GridRow';
import { mockData } from './mock';
import { TermsMoth } from './TermsMoth';
import { DEAL_TYPE, Override } from './Override';

export const GridTableInterstate: React.FC = () => {
    return (
        <Grid
            data-testid="payment-grid"
            container
            justifyContent="center"
            alignItems="center"
            marginTop="20px"
            sx={{
                '& > div:first-of-type': {
                    borderTopLeftRadius: '3px'
                },
                '& > div:nth-of-type(4)': {
                    borderTopRightRadius: '3px'
                }
            }}
        >
            <TermsMoth />
            {mockData.map((item, index) => (
                <Grid
                    display="contents"
                    sx={{
                        '& > div:first-of-type': {
                            background: '#f4f4f4'
                        }
                    }}
                >
                    <GridRow data={item} key={item[index].id + item[index].monthlyPayment} />
                </Grid>
            ))}
            <Override dealType={DEAL_TYPE.MF} />
        </Grid>
    );
};
