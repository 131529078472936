import { TableColumn } from '@interstate-beta/components/Table';
import numeral from 'numeral';
import isEqual from 'lodash.isequal';
import { LenderGuideLineItem } from '@makemydeal/dr-dash-store';

type DataIndex = string | number | readonly (string | number)[];

export const formatInteger = (amount: number): string => {
    return numeral(amount).format('0,0');
};

/**
 * Remove a column (by dataIndex) from the columns array defined for Interstate Table without mutating the original array. This will work for nested data indexes with array values.
 * @param cols All columns before modification
 * @param removeFields List of data indexes to remove. (this might an array of strings or an array of string arrays)
 * @returns A new array with filtered columns.
 */
export const removeColumns = (cols: TableColumn[], removeFields: DataIndex[]) => {
    const result = [...cols];
    removeFields.forEach((dataIndex) => {
        const ix = indexOfDataIndex(result, dataIndex);
        if (ix < 0) return;
        result.splice(ix, 1);
    });
    return result;
};

/**
 * Return the index of a column matching the dataIndex provided
 * @param cols Columns to search
 * @param lookForIndex dataIndex to check for
 * @returns index of the matching column or -1
 */
export const indexOfDataIndex = (cols: TableColumn[], lookForIndex: DataIndex): number => {
    const found = cols.find((c) => isEqual(c.dataIndex, lookForIndex)); // could be string or string[]; need to compare values
    if (!found) return -1;
    const ix = cols.indexOf(found);
    return ix;
};

export const filterUniqueMessages = (guideLineAlerts: LenderGuideLineItem[]): LenderGuideLineItem[] => {
    const seenMessages = new Set<string>();
    return guideLineAlerts.filter((messageItem) => {
        const uniqueKey = `${messageItem.lenderCode}_${messageItem.message}`;
        if (seenMessages.has(uniqueKey)) {
            return false;
        } else {
            seenMessages.add(uniqueKey);
            return true;
        }
    });
};
