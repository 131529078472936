// externals
import { Reducer } from 'redux';

// libraries
import type { PushToRouteOneState } from '@makemydeal/dr-dash-types';
import { UPDATE_PUSH_TO_ROUTEONE_TIMESTAMP } from '../actionTypes/pushToRouteOneActionTypes';
import { initActionTypes } from '@makemydeal/dr-shared-store';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export const pushToRouteOneInitialState: PushToRouteOneState = {
    timestamp: '',
    dealXgVersion: ''
};

export type PushToRouteOneReducer = Reducer<PushToRouteOneState>;

export const reducer: PushToRouteOneReducer = (state: PushToRouteOneState = pushToRouteOneInitialState, action: any) => {
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            const { pushToRouteOne } = action.payload;
            return { ...state, ...pushToRouteOne };
        }
        case UPDATE_PUSH_TO_ROUTEONE_TIMESTAMP: {
            const { timestamp, dealXgVersion } = action.payload;
            return { ...state, timestamp, dealXgVersion };
        }
        default: {
            return state;
        }
    }
};
