// external
import styled from 'styled-components';

export const SELECTED_ROW_CLASSNAME = 'products-table-selected-row';
export const HOVER_ROW_CLASSNAME = 'ant-table-row';

export const PRODUCT_PLAN_CLASSNAME = 'products-table-product-plan';

export const RATES_LOADING_SPINNER = 'rates-loading-spinner';

export const VehicleProtectionTableActions = styled.div`
    button {
        width: 50%;
    }
`;

export const VehicleProtectionTableContainer = styled.div`
    table tbody {
        tr.${SELECTED_ROW_CLASSNAME} {
            td {
                background-color: ${({ theme }) => theme.tokens.BaseColorBlue50};
            }

            td:first-of-type {
                border-left: 0.5rem solid ${({ theme }) => theme.tokens.ComponentTableCheckedBorder};
            }

            background-color: ${({ theme }) => theme.tokens.BaseColorBlue50};
        }

        tr.${HOVER_ROW_CLASSNAME}:hover {
            background-color: ${({ theme }) => theme.tokens.BaseColorBlue50};
        }

        td.${PRODUCT_PLAN_CLASSNAME} > div {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            white-space: normal;
            overflow: hidden;
        }

        .${RATES_LOADING_SPINNER} {
            display: flex;
            justify-content: center;

            & svg {
                height: 16px;
                width: 16px;
            }

            & #ids-spinner-root {
                // need to override inline styles, as there is no way to pass them as property
                height: 16px !important;
                width: 16px !important;
                color: gray;
            }

            & #interstitial-overlay-root {
                min-width: 18px;
                min-height: 18px;
                background-color: transparent;
            }

            & #interstitial-content-root {
                color: gray;
            }
        }
    }
`;

export const VppTableCheckBoxContainer = styled.div`
    span > input {
        margin: 0;
    }

    span > svg {
        width: auto;
        height: auto;
    }
`;
