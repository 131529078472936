// libraries
import { StateTree, DealHistory } from '@makemydeal/dr-dash-types';
import { accessoriesSelectors, compositeSelectors, offerSelectors, vehicleProtectionSelectors } from '@makemydeal/dr-dash-store';

// consts/enums
import * as constants from '../../constants';

// utils
import {
    convertAccessoryToLineItem,
    convertDealerFeeToLineItem,
    convertGovernmentFeeToLineItem,
    convertVppProductToLineItem
} from '../utils/transform';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// components
import { Grid } from '@interstate/components/Grid';
import LineItemWithChildren from '../common/LineItemWithChildren';
import DealSummaryItem from '../common/DealSummaryItem';

// hooks
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';
import { IDealerFee as DealerFee } from '@makemydeal/dr-platform-shared';

export type AddOnsProps = {
    deal?: DealHistory;
    hasDelta?: boolean;
};

const Addons = ({ deal, hasDelta = false }: AddOnsProps) => {
    const state = useDealRootState(deal);

    // total add-ons
    const addons = compositeSelectors.getTotalAddons(state as StateTree);

    // accessories
    const accessoriesTotal = accessoriesSelectors.getAccessoriesTotalAmount(state);
    const itemizedAccessories = accessoriesSelectors.getAccessoriesList(state).map(convertAccessoryToLineItem);

    // protection products
    const protectionProductsTotal = vehicleProtectionSelectors.getVppSummary(state).totalProductPrice;
    const protectionProductsItems = vehicleProtectionSelectors.getVppSelectedProducts(state).map(convertVppProductToLineItem);

    // dealer fees
    let dealerFeesItems: DealerFee[] = offerSelectors.getDealerFees(state as any);
    dealerFeesItems = dealerFeesItems.filter((fee) => fee.dealerFeeAmount > 0);
    const dealerFeesTotal = offerSelectors.getDealerFeeTotal(state as StateTree);
    const dealerFeeItems = dealerFeesItems.map(convertDealerFeeToLineItem);

    // government fees
    const governmentFeeTotal = offerSelectors.getDmvFeeTotal(state);
    let dmvFeeItem = offerSelectors.getDmvFeeList(state);
    dmvFeeItem = dmvFeeItem.filter((fee) => fee.amount > 0);
    const govFeeItems = dmvFeeItem.map(convertGovernmentFeeToLineItem);

    return (
        <DealSummaryItem label={constants.TOTAL_ADD_ONS} value={formatDollarsAndCents(addons)} hasDelta={hasDelta}>
            <Grid container flexDirection="column">
                <LineItemWithChildren
                    label={constants.ACCESSORIES}
                    value={formatDollarsAndCents(accessoriesTotal)}
                    childItems={itemizedAccessories}
                />
                <LineItemWithChildren
                    label={constants.PROTECTION_PRODUCTS}
                    value={formatDollarsAndCents(protectionProductsTotal)}
                    childItems={protectionProductsItems}
                />
                <LineItemWithChildren
                    label={constants.FEES}
                    value={formatDollarsAndCents(dealerFeesTotal)}
                    childItems={dealerFeeItems}
                />
                <LineItemWithChildren
                    label={constants.GOV_FEES}
                    value={formatDollarsAndCents(governmentFeeTotal)}
                    childItems={govFeeItems}
                />
            </Grid>
        </DealSummaryItem>
    );
};

export default Addons;
