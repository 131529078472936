// externals
import { useSelector } from 'react-redux';

// store
import { offerReduxSelectors, paymentSelectors } from '@makemydeal/dr-dash-store';
import { ScopedStateProvider } from '@makemydeal/dr-shared-store';

// components
import { Typography } from '@interstate/components/Typography';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { OfficialCreditDecisionToggle } from './OfficialCreditDecisionToggle';
import { DeskingCard } from './DeskingCard';

import { HeaderRowWrapper } from '../DeskingActivity.styles';
import { DeskingCardsContainer } from './DeskingCard.style';

const DRAFT_DEAL_LIMIT = 3;

export interface DeskingActivityDealMultipleProps {
    scopesOrder: string[];
    primaryScopeId: string;
}

export const DeskingActivityDealMultiple = ({ scopesOrder, primaryScopeId }: DeskingActivityDealMultipleProps) => {
    const isCreditDecisionEnabled = useSelector(offerReduxSelectors.getCreditDecisionEnabled) as boolean;
    const isPaymentCalculating = useSelector(paymentSelectors.isCalculatingPayment);

    const theme = useInterstateTheme();

    const isSingleCardView = scopesOrder.length === 1;
    const isDuplicateDealAllowed = scopesOrder.length < DRAFT_DEAL_LIMIT && !isCreditDecisionEnabled && !isPaymentCalculating;

    return (
        <>
            <HeaderRowWrapper>
                <Typography variant="h3">Current Working Offer</Typography>
                <OfficialCreditDecisionToggle disabled={!isSingleCardView} />
            </HeaderRowWrapper>

            <DeskingCardsContainer cardsAmount={scopesOrder.length} theme={theme}>
                {scopesOrder.map((scopeId) => (
                    <ScopedStateProvider scopeId={scopeId} key={scopeId}>
                        <DeskingCard
                            scopeId={scopeId}
                            isDuplicateDealAllowed={isDuplicateDealAllowed}
                            isSingleCardView={isSingleCardView}
                            isPrimaryScope={primaryScopeId === scopeId}
                        ></DeskingCard>
                    </ScopedStateProvider>
                ))}
            </DeskingCardsContainer>
        </>
    );
};
