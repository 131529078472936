import { FunctionComponent, PropsWithChildren } from 'react';
import { DetailContainer } from '../trade.interstate.style';
import { Typography } from '@interstate/components/Typography';

export interface Props {
    label: string;
}

export const SummaryDataBox: FunctionComponent<PropsWithChildren<Props>> = ({ label, children }) => {
    return (
        <DetailContainer>
            <Typography variant="label-md" color="sem.color.on-surface.muted" sx={{ marginBottom: '8px' }}>
                {label}
            </Typography>
            <Typography variant="body-md" tag="span" data-testid="trade-engine-value-label">
                {children}
            </Typography>
        </DetailContainer>
    );
};
