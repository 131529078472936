// externals
import React from 'react';
import { useSelector } from 'react-redux';

// libraries
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import {
    ACTIVITY_STATE_COMPLETED,
    ACTIVITY_STATE_IN_PROGRESS,
    ACTIVITY_STATE_NOT_STARTED,
    ActivityState
} from '@makemydeal/dr-dpm-client';
import { Card } from '@interstate/components/Card';
import { Grid } from '@interstate/components/Grid';
import { CheckCircleIcon } from '@interstate/components/Icons/CheckCircleIcon';
import { InformationCircleIcon } from '@interstate/components/Icons/InformationCircleIcon';
import { Action } from '@interstate/components/Action';
import { useInterstateToken } from '@interstate/components/InterstateThemeProvider';
import { StyledCardMediaContainerCompleted, StyledCardMediaContainerNotCompleted } from './DealActivityCard.interstate.style';
import { Typography } from '@interstate/components/Typography';

// interfaces/types
import type { StateTree } from '@makemydeal/dr-dash-types';
import { Activities, LabelMappings } from './DealActivityTypes';

export interface IDealActivityCardProps {
    name: Activities;

    // value shown on card ( '$100', 'Submitted', etc... )
    cardValue: string;

    // card will display 'Completed' on header and cardValue will be shown
    completed: boolean;

    // card only will display the header when is the state is InProgress, or Completed)
    dpmState: ActivityState | undefined;

    // This will override displaying the card anchor
    showCardAnchor?: boolean;

    // card will display 'Recommended Next Step' on header and cardValue will be shown
    recommended?: boolean;
    /**
     * when true, card will display 'Not Started' and the dealer cannot start the activity
     * when false, card will display 'Start' and the dealer can start the activity
     */
    disabled?: boolean;

    action?(): void;
    // When provided, this overrides the link text
    linkText?: string;
    htmlId?: string;
    additionalContent?: any;
}

const DealActivityCard = ({
    action,
    completed,
    showCardAnchor = false,
    disabled = false,
    cardValue,
    name,
    linkText,
    dpmState,
    recommended = false,
    additionalContent
}: IDealActivityCardProps) => {
    const state = useSelector((state: StateTree) => state);
    const isDpmEnabledAfterBootstrap = featureToggleSelectors.isDpmEnabledAfterBootstrap(state);
    const showCardValue = Boolean(completed && !disabled);
    const dpmHeaderText = dpmState === ACTIVITY_STATE_COMPLETED ? 'Completed' : 'In Progress';
    const shouldDisplayDpmHeader = recommended || (dpmState !== ACTIVITY_STATE_NOT_STARTED && Boolean(dpmState));
    const shouldDisplayHeader = isDpmEnabledAfterBootstrap ? shouldDisplayDpmHeader : completed;
    const shouldDisplayCheckCircle = isDpmEnabledAfterBootstrap
        ? dpmState === ACTIVITY_STATE_COMPLETED || dpmState === ACTIVITY_STATE_IN_PROGRESS
        : completed;
    const valueTestId = `${name}-value`;
    const linkTestId = `${name}-link`;
    const anchorText = linkText || (completed ? 'Edit' : 'Start');
    const shouldDisplayCardAnchor = showCardAnchor || (!completed && !disabled);

    // color token
    const getToken = useInterstateToken();
    const colorToken = {
        colorComplete: getToken('base.color.white') || '#fff', // fallback to default color if the hook returns undefined
        colorNotComplete: getToken('base.color.blue.600') || '#2372b8',
        borderBottomNotComplete: getToken('base.color.gray.100') || '#e3e3e3',
        backgroundColorComplete: getToken('base.color.green.500') || '#479230'
    };

    const headerAnchor = shouldDisplayCardAnchor && (
        <Action size="sm" onClick={action} data-testid={linkTestId}>
            {anchorText}
        </Action>
    );

    const MediaContainer =
        dpmState === ACTIVITY_STATE_COMPLETED ? StyledCardMediaContainerCompleted : StyledCardMediaContainerNotCompleted;

    const cardMedia = shouldDisplayHeader && (
        <MediaContainer justifyContent="center" alignItems="center" gap="8px" container colorToken={colorToken}>
            {shouldDisplayCheckCircle && dpmState === ACTIVITY_STATE_COMPLETED ? <CheckCircleIcon /> : <InformationCircleIcon />}
            <span data-testid={`dpm-header-text-${name}`}>
                {recommended ? 'Recommended Next Step' : isDpmEnabledAfterBootstrap ? dpmHeaderText : 'Completed'}
            </span>
        </MediaContainer>
    );

    const cardHeader = (
        <Grid container={completed} justifyContent="space-between" p={0} mb={completed ? '8px' : 0}>
            <Typography variant="h5">{LabelMappings[name]}</Typography>
            {headerAnchor}
        </Grid>
    );

    let cardContent = !!showCardValue && <span data-testid={valueTestId}>{cardValue}</span>;

    if (additionalContent) {
        cardContent = additionalContent;
    }
    return (
        <Card
            mediaPosition="top"
            media={cardMedia}
            header={cardHeader}
            content={cardContent}
            data-testid={`deal-activity-card-${name}`}
            sx={{ height: 'auto' }}
        />
    );
};

export default DealActivityCard;
