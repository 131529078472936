import { NumericInput } from '@interstate/components/NumericInput';
import { FC, useEffect, useState } from 'react';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { convertToNumber } from '../../utils/formatUtils';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';

interface Props {
    fieldName: string;
    fieldValue: number;
    index: number;
    handleInputChange: (event: any) => void;
    errorDisplay: string;
    required: boolean;
    deleted: boolean;
}

export const IncentivesAmoundField: FC<Props> = ({
    fieldName,
    index,
    handleInputChange,
    errorDisplay,
    required,
    deleted,
    fieldValue
}) => {
    const [value, setValue] = useState(fieldValue);
    const [textValue, setTextValue] = useState(formatDollarsAndCents(fieldValue));

    const handleChange = (e: InterstateOnChangeEvent<string>) => setTextValue(e.target.value);
    const handleBlur = (e: InterstateOnChangeEvent<string>) => setTextValue(formatDollarsAndCents(value));

    useEffect(() => {
        const newValue = convertToNumber(textValue);
        if (newValue !== value) {
            setValue(newValue);
            handleInputChange({ target: { value: newValue }, isValid: true });
        }
    }, [textValue, handleInputChange, value]);

    useEffect(() => {
        if (fieldValue === value) return;
        setValue(fieldValue);
        setTextValue(formatDollarsAndCents(fieldValue));
    }, [fieldValue, value]);

    return (
        <>
            <NumericInput
                inputPrefix={'$'}
                autoInsertCommas
                allowDecimal
                decimalMaxLength={2}
                decimalMinLength={2}
                placeholder="0.00"
                onBlur={handleBlur}
                displayLabel={false}
                data-testid={`${fieldName}-${index}`}
                name={`${fieldName}-${index}`}
                onChange={handleChange}
                value={textValue}
                errorMessage={errorDisplay}
                required={required}
                disabled={deleted}
                size="medium"
            />
        </>
    );
};
