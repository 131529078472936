import { useCallback, useEffect } from 'react';

// externals
import { useDispatch, useSelector } from 'react-redux';
import { SelectInputOption } from '@interstate/components/SelectInput';
import { TextInput } from '@interstate/components/TextInput';

// libraries
import {
    tradeInSelectors,
    tradeInComponentActionCreators,
    tradeInComponentSelectors,
    tradeInActionCreators
} from '@makemydeal/dr-dash-store';
import { updateVehicle } from '@makemydeal/dr-activities-tradein';

// components
import { SelectInput } from './TradeInSelectInput';

// constants
import { TRADE_IN_MAKE } from '@makemydeal/dr-dash-components';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

interface MakeFieldProps {
    isManualFormOpen: boolean;
}

const MakeField: React.FC<MakeFieldProps> = ({ isManualFormOpen }) => {
    const dispatch = useDispatch();
    const vehicleYear = useSelector(tradeInSelectors.getTradeYear);
    const vehicleMake = useSelector(tradeInSelectors.getTradeMake);
    const vehicleMakeId = useSelector(tradeInSelectors.getTradeMakeId);
    const tradeInVehicleMakes = useSelector(tradeInComponentSelectors.getTradeInComponentMakes);
    const tradeInVehicle = useSelector(tradeInComponentSelectors.getTradeInComponentVehicle);
    const vinHasBeenDecoded = useSelector(tradeInSelectors.vinHasBeenDecoded);
    const hasMultipleVin = useSelector(tradeInSelectors.hasMultipleVin);
    const disabled = vinHasBeenDecoded || hasMultipleVin || tradeInVehicleMakes.options.length === 0 || !Number(vehicleYear);

    const fetchTradeInComponentModels = useCallback(() => {
        if (vehicleYear && vehicleMake && vehicleMakeId) {
            dispatch(
                tradeInComponentActionCreators.fetchTradeInComponentModels(MANAGER_VIEW_APP_PREFIX, vehicleYear, vehicleMakeId)
            );
        }
    }, [dispatch, vehicleMake, vehicleMakeId, vehicleYear]);

    useEffect(() => {
        const make = tradeInVehicleMakes.options.find((option) => vehicleMake === option.name);

        if (make) {
            dispatch(tradeInActionCreators.updateTradeInMakeId(make.id));
        }
    }, [dispatch, tradeInVehicleMakes, vehicleMake]);

    useEffect(() => {
        fetchTradeInComponentModels();
    }, [vinHasBeenDecoded, fetchTradeInComponentModels]);

    const getTradeInMakes = (): SelectInputOption[] =>
        tradeInVehicleMakes.options.map((make) => ({ label: make.name, value: make.name })).sort();

    const onMakeChange = (event: any) => {
        const make = tradeInVehicleMakes.options.find((make) => make.name === event.target.value);
        dispatch(tradeInActionCreators.updateTradeInMake(make));
        dispatch(
            updateVehicle({
                ...tradeInVehicle,
                make,
                model: { id: '', name: '' },
                trim: { id: '', name: '', vehicleId: '' }
            })
        );
        if (make && make.id) {
            dispatch(tradeInComponentActionCreators.fetchTradeInComponentModels(MANAGER_VIEW_APP_PREFIX, vehicleYear, make.id));
        }
    };

    const makeValue = Number(vehicleYear) === 0 || vehicleMake === 'default' ? '' : vehicleMake;

    return (
        <>
            {isManualFormOpen && (
                <>
                    {vinHasBeenDecoded && !hasMultipleVin ? (
                        <TextInput
                            id="trade-makes-text-input"
                            data-testid="trade-makes-text-input"
                            label={TRADE_IN_MAKE}
                            name={TRADE_IN_MAKE}
                            value={makeValue}
                            onChange={null as any}
                            disabled
                        />
                    ) : (
                        <SelectInput
                            id="trade-makes-select-input"
                            data-testid="trade-makes-select-input"
                            label={TRADE_IN_MAKE}
                            name={TRADE_IN_MAKE}
                            onChange={onMakeChange}
                            options={getTradeInMakes()}
                            value={makeValue}
                            placeholder="Select"
                            disabled={disabled}
                            required
                            displayDeselectOption={false}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default MakeField;
