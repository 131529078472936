import { useSelector, useDispatch } from 'react-redux';

import { Switch } from '@interstate/components/Switch';
import { offerReduxSelectors, offerReduxActionCreators } from '@makemydeal/dr-dash-store';

import { PAYMENT_GRID } from '../../constants';
import { FC, useEffect } from 'react';
import { StateTree } from '@makemydeal/dr-dash-types';
import { OfferType } from '@makemydeal/dr-platform-types';
import { SwitchEventValue } from '@interstate/components/Switch/Types/switch';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents/Types/InterstateInputEventTypes';

interface PaymentGridToggleProps {
    currentOfferType: OfferType;
    disabled?: boolean;
}

const PaymentGridToggle: FC<PaymentGridToggleProps> = ({ currentOfferType, disabled }) => {
    const dispatch = useDispatch();
    const paymentGridEnabled: boolean = useSelector((state: StateTree) =>
        offerReduxSelectors.getPaymentGridToggle(state, currentOfferType)
    );
    const isCreditDecisionEnabled = useSelector(offerReduxSelectors.getCreditDecisionEnabled);

    useEffect(() => {
        if (isCreditDecisionEnabled && paymentGridEnabled) {
            dispatch(offerReduxActionCreators.updatePaymentGridToggle(false));
        }
    }, [isCreditDecisionEnabled, paymentGridEnabled, dispatch]);

    const isChecked = disabled ? false : paymentGridEnabled;

    const handleToggleClick = (e: InterstateOnChangeEvent<SwitchEventValue>) => {
        const checked = e.target.value === 'checked';
        dispatch(offerReduxActionCreators.updatePaymentGridToggle(checked));
    };

    const id = 'payment-grid-toggle';
    return (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Switch
                name={id}
                checked={isChecked}
                label={PAYMENT_GRID}
                labelSx={{
                    marginBottom: '16px',
                    width: 'unset'
                }}
                layout="vertical"
                onClick={handleToggleClick}
                disabled={disabled || !!isCreditDecisionEnabled}
                data-testid={id}
            />
        </div>
    );
};

export default PaymentGridToggle;
