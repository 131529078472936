import { useEffect, useState } from 'react';

import { InterstateOnChangeCallback, InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { DatePickerEventValue } from '@interstate/components/DatePicker/Types/datePickerTypes';
import { SelectInputOption } from '@interstate/components/SelectInput';
import { TextInputValue } from '@interstate/components/TextInput';

import { LIENHOLDER_FORM_SUMMARY_ADDRESS, StateCodes } from './constants';
import { LienholderFormData, LienholderStateData } from './types';
import { formatDate, formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import {
    LIEN_HOLDER,
    LIEN_HOLDER_ACCOUNT_NUMBER,
    LIEN_HOLDER_ADDRESS_BANK,
    LIEN_HOLDER_ADDRESS_CITY,
    LIEN_HOLDER_ADDRESS_STATE,
    LIEN_HOLDER_ADDRESS_ZIP,
    PAYOFF_DUE_DATE,
    PER_DIEM
} from '../../../constants';
import { convertToNumber } from '../../../utils/formatUtils';

export const getStateCodesSelectInputValues = (): SelectInputOption[] =>
    StateCodes.map((stateCode) => ({
        value: stateCode,
        label: stateCode
    }));

export const checkLienholderInfoAvailability = ({
    lienholder,
    lienholderAccountNumber,
    lienholderAddress: { address, city, postalCode, stateProvince },
    payoffDueDate,
    perDiem
}: LienholderFormData) => {
    return (
        !!lienholder ||
        !!lienholderAccountNumber ||
        !!address ||
        !!city ||
        !!postalCode ||
        !!stateProvince ||
        !!payoffDueDate ||
        !!perDiem
    );
};

export const getLienholderFormData = (sourceLienholderData: LienholderStateData): LienholderFormData => ({
    lienholder: sourceLienholderData.lienholder,
    lienholderAccountNumber: sourceLienholderData.lienholderAccountNumber,
    lienholderAddress: sourceLienholderData.lienholderAddress,
    payoffDueDate: sourceLienholderData.goodThroughDate ? new Date(sourceLienholderData.goodThroughDate) : null,
    perDiem: sourceLienholderData.perDiemInterest
});

export type SummaryData = Partial<ReturnType<typeof getSummaryData>>;

export const getSummaryData = ({
    lienholder,
    lienholderAccountNumber,
    lienholderAddress: { address, city, postalCode, stateProvince },
    payoffDueDate,
    perDiem
}: LienholderFormData) => ({
    [LIEN_HOLDER]: lienholder,
    [LIEN_HOLDER_ACCOUNT_NUMBER]: lienholderAccountNumber,
    [PAYOFF_DUE_DATE]: (payoffDueDate && formatDate(payoffDueDate)) || '',
    [PER_DIEM]: formatDollarsAndCents(perDiem),
    [LIENHOLDER_FORM_SUMMARY_ADDRESS]: {
        [LIEN_HOLDER_ADDRESS_BANK]: address,
        [LIEN_HOLDER_ADDRESS_CITY]: city,
        [LIEN_HOLDER_ADDRESS_STATE]: stateProvince,
        [LIEN_HOLDER_ADDRESS_ZIP]: postalCode
    }
});

export const extractValueFromDatePickerEvent = (event: InterstateOnChangeEvent<DatePickerEventValue>): Date | null => {
    const stringValue = event.target.value.dateValue;

    return stringValue ? new Date(stringValue) : null;
};

export const getRequiredErrorMessage = (obj: Record<string, boolean>, key: string) => {
    return obj[key] ? 'Required' : undefined;
};

// This workaround is needed until interstate fixes the issue with SelectInput and NumericInput
export const isLienholderAddressInvalid = (field: string, e: InterstateOnChangeEvent<TextInputValue>) => {
    const {
        isValid,
        target: { value }
    } = e;

    if (!isValid && field !== 'stateProvince') {
        return true;
    }

    if (field === 'postalCode' && value && typeof value === 'string' && value.length !== 5) {
        return true;
    }

    return false;
};

// This workaround is needed until interstate fixes the issue with DatePicker
export const isLienholderDataInvalid = (field: string, value?: number | Date | null) => {
    return field === 'payoffDueDate' && value && value < new Date();
};

type onChangeResult = {
    value: string;
    handleChange: InterstateOnChangeCallback<string>;
    handleBlur: InterstateOnChangeCallback<string>;
};

export const useHandlePerDiemChange = (
    initialValue: number,
    onChange: (value: 'perDiem') => InterstateOnChangeCallback<any>
): onChangeResult => {
    const [value, setValue] = useState(initialValue);
    const [textValue, setTextValue] = useState(formatDollarsAndCents(initialValue));

    const handleChange = (e: InterstateOnChangeEvent<string>) => setTextValue(e.target.value);
    const handleBlur = (e: InterstateOnChangeEvent<string>) => setTextValue(formatDollarsAndCents(value));

    useEffect(() => {
        const newValue = convertToNumber(textValue);
        setValue(newValue);
        if (newValue !== value) {
            onChange('perDiem')({ target: { value: newValue }, isValid: true });
        }
    }, [onChange, textValue, value]);

    useEffect(() => {
        if (initialValue === value) return;
        setValue(initialValue);
        setTextValue(formatDollarsAndCents(initialValue));
    }, [initialValue, value]);

    return { value: textValue, handleChange, handleBlur };
};
