import { Card, CardActions, CardContent } from '@interstate-beta/components/Card';
import { Typography } from '@interstate-beta/components/Typography';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { ExpandableArea, ExpandableAreaProvider, ExpandableAreaSection, FlexCol } from '@makemydeal/dr-dash-components';
import { programActionCreators, programsSelectors } from '@makemydeal/dr-dash-store';
import { Incentive } from '@makemydeal/dr-dash-types';
import { DateTime } from 'luxon';
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlexLabel, FooterContainer, FooterRightSpan, GridAutoCol, HeaderContainer, SpanGray } from './styles';

export const IncentiveCard: FC<{ incentive: Incentive; conditional?: boolean }> = ({ incentive, conditional = false }) => {
    const dispatch = useDispatch();
    const selected = useSelector(programsSelectors.isIncentiveSelectedSelector(incentive!.incentiveId));
    const shouldExclude = useSelector(programsSelectors.isIncentiveExcluded(incentive.incentiveId));

    const excludedIncentiveText = 'Incentive unavailable with current selections';

    const formattedIncentiveEndDate = useMemo(() => {
        if (incentive.endDate == null) return '';
        const date = DateTime.fromFormat(incentive.endDate, 'M/d/yyyy');
        if (date.isValid) return date.toLocaleString(DateTime.DATE_SHORT);
        return '';
    }, [incentive.endDate]);

    return (
        <Card
            sx={{ height: 'auto !important' }} // NOTE: prevents stupid MUI layout from overlapping when slideout is expanded.
            content={
                <CardContent>
                    <FlexCol gap="0">
                        {incentive.endDate != null && (
                            <Typography variant="body-sm" sx={{ marginBottom: '1rem' }} data-testid="date-subtitle">
                                {formattedIncentiveEndDate}
                            </Typography>
                        )}
                        {incentive.description && incentive.description !== incentive.programName && (
                            <ExpandableAreaProvider>
                                <ExpandableArea data-testid="ExpandableArea">{incentive.description}</ExpandableArea>
                                <div>
                                    <ExpandableAreaSection />
                                </div>
                            </ExpandableAreaProvider>
                        )}
                    </FlexCol>
                </CardContent>
            }
            footer={
                <CardActions>
                    <FooterContainer>
                        {!conditional && <SpanGray>Included with this program.</SpanGray>}
                        {conditional && (
                            <FlexLabel>
                                <input
                                    type="checkbox"
                                    checked={selected}
                                    data-testid={`toggle-conditional-${incentive.incentiveId}`}
                                    disabled={shouldExclude}
                                    onChange={(e) => {
                                        dispatch(programActionCreators.toggleSelectedIncentive(incentive, e.target.checked));
                                    }}
                                />{' '}
                                {shouldExclude ? excludedIncentiveText : 'Select'}
                            </FlexLabel>
                        )}
                    </FooterContainer>
                </CardActions>
            }
            header={
                <HeaderContainer>
                    <GridAutoCol>
                        <Typography variant="body-md" sx={{ fontWeight: '600' }}>
                            {incentive.programName}
                        </Typography>
                        <FooterRightSpan data-testid="incentive-amount">
                            {incentive!.cash > 0 && formatDollarsAndCents(incentive.cash)}
                        </FooterRightSpan>
                    </GridAutoCol>
                </HeaderContainer>
            }
            id={`card-id-${incentive.incentiveId}`}
        />
    );
};
