// components
import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';

// styling
import { StyledAlertWrapper } from './TableGlobalAlerts.style';

// types
import { TableGlobalAlertInputProps } from '../../types';
import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';

const TableGlobalAlertWarning = ({ message, name }: TableGlobalAlertInputProps) => {
    return (
        <InterstateThemeProvider
            themeName="Interstate"
            applicationName="Manager View"
            scopeName="Manager View"
            themeRegistries={[interstateThemeRegistry]}
        >
            <StyledAlertWrapper data-testid={`${name}-warning-alert`}>
                <Alert role="banner" type="caution">
                    <Typography variant="label-md">{message}</Typography>
                </Alert>
            </StyledAlertWrapper>
        </InterstateThemeProvider>
    );
};

export default TableGlobalAlertWarning;
