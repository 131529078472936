// externals
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

// selectors
import { scopedActionsCreators } from '@makemydeal/dr-shared-store';

// components
import { Terms } from '@makemydeal/dr-dash-components';
import { Accordion, AccordionDetails, AccordionSummary } from '@interstate/components/AccordionGroup';
import { Action } from '@interstate/components/Action';
import { Grid } from '@interstate/components/Grid';
import { DocumentDuplicateIcon } from '@interstate/components/Icons';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { Typography } from '@interstate/components/Typography';

import InterstateAccessoriesCard from '../accessories/AccessoriesCard.interstate';
import InterstateDealSummary from '../dealSummary/DealSummary.interstate';
import DeskingActionsInterstate from '../DeskingActions.interstate';
import { DeskingAlerts } from '../DeskingAlerts';
import { FeesCard } from '../fees/FeesCard';
import OfferCommentsInterstate from '../OfferComments.interstate';
import { RebatesIndex } from '../rebates/index';
import TaxCard from '../taxes/Taxes/TaxCard';
import InterstateTradeFormCard from '../tradeForm/TradeFormCard.interstate';
import { VehicleProtectionPreview } from '../vehicleProtection/VehicleProtectionPreview';
import { VehicleInfoCard } from '../vehicleInfo/VehicleInfoCard';
import {
    DeskingCardBlock,
    StyledDeskingCard,
    DeskingCardBlocksContainer,
    DeskingCardHeader,
    DeskingCardLeftPanel,
    DeskingCardHeaderActions,
    DeskingCardHeaderActionsMenu,
    DeskingCardLeftPanelContent
} from './DeskingCard.style';
import { DeskingCardDealLabel } from './DeskingCardDealLabel';

export interface DeskingCardProps {
    scopeId: string;
    isDuplicateDealAllowed: boolean;
    isSingleCardView: boolean;
    isPrimaryScope: boolean;
}

export const DeskingCard = memo(({ isPrimaryScope, scopeId, isDuplicateDealAllowed, isSingleCardView }: DeskingCardProps) => {
    const dispatch = useDispatch();
    const theme = useInterstateTheme();

    const handleDuplicate = useCallback(
        (scopeId: string) => {
            dispatch(scopedActionsCreators.cloneScope(scopeId));
        },
        [dispatch]
    );

    return (
        <StyledDeskingCard data-testid={`deal-content-card-${scopeId}`} isSingleCardView={isSingleCardView} theme={theme}>
            <DeskingCardHeader>
                <DeskingCardHeaderActions theme={theme}>
                    {!isSingleCardView && (
                        <DeskingCardDealLabel isPrimaryScope={isPrimaryScope} scopeId={scopeId}></DeskingCardDealLabel>
                    )}
                    <DeskingCardHeaderActionsMenu>
                        <Action
                            actionIcon={{ icon: <DocumentDuplicateIcon />, start: true }}
                            id="duplicate-deal-draft-button"
                            data-testid="duplicate-deal-draft-button"
                            onClick={() => handleDuplicate(scopeId)}
                            size="lg"
                            disabled={!isDuplicateDealAllowed}
                        />
                    </DeskingCardHeaderActionsMenu>
                </DeskingCardHeaderActions>

                <VehicleInfoCard />

                <DeskingAlerts />
            </DeskingCardHeader>

            {isSingleCardView && (
                <DeskingCardLeftPanel>
                    <Typography variant="h3" sx={{ margin: 0, marginTop: theme.tokens.ComponentCardPadding }}>
                        Payment Details
                    </Typography>

                    <DeskingCardLeftPanelContent>
                        <InterstateDealSummary />
                    </DeskingCardLeftPanelContent>
                </DeskingCardLeftPanel>
            )}

            <DeskingCardBlocksContainer>
                {!isSingleCardView && (
                    <DeskingCardBlock rowName="payment-details">
                        <Accordion contained={true}>
                            <AccordionSummary sx={{ padding: theme.tokens.ComponentCardPadding }}>Payment Details</AccordionSummary>
                            <AccordionDetails>
                                <InterstateDealSummary />
                            </AccordionDetails>
                        </Accordion>
                    </DeskingCardBlock>
                )}

                <DeskingCardBlock rowName="terms">
                    <Terms />
                </DeskingCardBlock>

                <DeskingCardBlock rowName="trade">
                    <InterstateTradeFormCard />
                </DeskingCardBlock>

                <DeskingCardBlock rowName="tax">
                    <TaxCard />
                </DeskingCardBlock>

                <DeskingCardBlock rowName="fees">
                    <FeesCard />
                </DeskingCardBlock>

                <DeskingCardBlock rowName="rebates">
                    <RebatesIndex />
                </DeskingCardBlock>

                <DeskingCardBlock rowName="vpp">
                    <VehicleProtectionPreview />
                </DeskingCardBlock>

                <DeskingCardBlock rowName="accessories">
                    <Grid container flexDirection="row" rowGap={2} spacing={2}>
                        <Grid xs={12} sm={12} lg={12}>
                            <InterstateAccessoriesCard />
                        </Grid>
                    </Grid>
                </DeskingCardBlock>

                <DeskingCardBlock rowName="comments">
                    <OfferCommentsInterstate />
                </DeskingCardBlock>

                <DeskingCardBlock rowName="actions">
                    <DeskingActionsInterstate />
                </DeskingCardBlock>
            </DeskingCardBlocksContainer>
        </StyledDeskingCard>
    );
});
