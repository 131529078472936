import { SelectInput } from '@interstate-beta/components/SelectInput';
import { Typography } from '@interstate-beta/components/Typography';
import { manualIncentivesActionCreators, manualIncentivesSelectors } from '@makemydeal/dr-dash-store';
import { INCENTIVE_TYPE_VALUES } from '@makemydeal/dr-dash-types';
import { ManualIncentiveEditEntry } from '@makemydeal/dr-platform-types';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIncentiveDisplayType } from '../utils';
import { columnMappings } from './columnMappings';
import { FlexCol } from '@makemydeal/dr-dash-components';

export const TypeField: FC<{ index: number; field: keyof ManualIncentiveEditEntry }> = ({ field, index }) => {
    const dispatch = useDispatch();
    const showErrors = useSelector(manualIncentivesSelectors.getManualIncentivesShowValidation);
    const isEmpty = useSelector(manualIncentivesSelectors.isManualIncentiveFieldEmptyAtIndex(index, field));

    const deleted = useSelector(
        manualIncentivesSelectors.getManualIncentiveEditValueAtIndexAndField(index, 'deleted')
    ) as ManualIncentiveEditEntry['deleted'];

    const column = columnMappings.find((c) => c.fieldName === field)!;

    const manual = useSelector(
        manualIncentivesSelectors.getManualIncentiveEditValueAtIndexAndField(index, 'manual')
    ) as ManualIncentiveEditEntry['manual'];

    const fieldValue = useSelector(manualIncentivesSelectors.getManualIncentiveEditValueAtIndexAndField(index, field));
    const incentive = useSelector(manualIncentivesSelectors.getManualIncentiveEditEntryAtIndex(index));
    const incentiveDisplayType = getIncentiveDisplayType(incentive);
    const isFromProgramIncentives = useSelector(manualIncentivesSelectors.getManualIncentiveEditIsFromProgram(index));
    const selectOptions = [INCENTIVE_TYPE_VALUES.dealerCash, INCENTIVE_TYPE_VALUES.customerCash];

    const handleChange = (fieldName: string, value: string) => {
        dispatch(manualIncentivesActionCreators.updateShowValidation(false));
        dispatch(manualIncentivesActionCreators.updateManualIncentiveField(index, fieldName, value));
    };

    return (
        <FlexCol justifyContent="start" alignItems="start" gap="4px">
            <label className="hidden-label">gap</label>
            {manual && !isFromProgramIncentives ? (
                <SelectInput
                    label={column.label}
                    displayLabel={false}
                    htmlId={`type-${index}`}
                    data-testid={`type-${index}`}
                    displayDeselectOption={false}
                    onChange={(e) => {
                        handleChange(field, e.target.value as string);
                    }}
                    name={`type-${index}`}
                    options={selectOptions}
                    optionsWidth="auto"
                    errorMessage={showErrors && isEmpty && !deleted && column.required ? 'Required' : undefined}
                    hasError={showErrors && isEmpty && !deleted && column.required}
                    value={fieldValue as string}
                    disabled={deleted}
                />
            ) : (
                <Typography variant="body-sm" sx={{ lineHeight: '40px' }}>
                    {incentiveDisplayType}
                </Typography>
            )}
        </FlexCol>
    );
};
