import React, { HTMLAttributes } from 'react';

import { vehicleProtectionSelectors } from '@makemydeal/dr-dash-store';

import { ShowWithSelector } from '../utils/ShowWithSelector';
import AccessoriesCard from './activities/AccessoriesCard';
import CreditAppCard from './activities/CreditAppCard';
import { DocUploadCard } from './activities/DocUploadCard';
import FinishDealCard from './activities/FinishDealCard.interstate';
import IncentivesCard from './activities/IncentivesCard';
import ReservationCard from './activities/ReservationCard';
import TestDriveCard from './activities/TestDriveCard';
import TradeCard from './activities/TradeCard';
import VppActivityVehicleProtectionCard from './activities/VppActivityVehicleProtectionCard';
import DigitalDealJacketLink from './activities/DigitalDealJacketLink';
import { ShowWithFeatureToggle } from '../utils/ShowWithFeatureToggle';

export const DealActivity: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
    return (
        <>
            <IncentivesCard />
            <TradeCard />
            <VppActivityVehicleProtectionCard />
            <AccessoriesCard />
            <CreditAppCard />
            <TestDriveCard />
            <DocUploadCard />
            <ReservationCard />
            <FinishDealCard />
            <ShowWithFeatureToggle name="enableDigitalDealJacket" active={<DigitalDealJacketLink />} />
        </>
    );
};
export default DealActivity;
