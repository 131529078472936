import { Action } from '@interstate/components/Action';
import { Typography } from '@interstate/components/Typography';

export interface ActionButtonProps {
    label: string;
    handleClick: () => void;
    dataTestId: string;
}

export const ActionButton = ({ label, handleClick, dataTestId }: ActionButtonProps) => (
    <Action onClick={handleClick} data-testid={dataTestId}>
        <Typography variant="anchor-block-sm" color="base.color.blue.700">
            {label}
        </Typography>
    </Action>
);
