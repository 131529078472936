// consts/enums
import { NAME } from '../common/taxesAndFeesConsts';

// interfaces/types
import { SimpleTableHeadColumn } from './Taxes/TableHead';

export const ADDITIONAL_CLASS = 'additional';
export const ADVANCED_TAX = 'advanced';
export const CAPITALIZED_TAXES_CATEGORY = 'Capitalized Taxes';
export const CITY_TAX_NAME = 'City';
export const COUNTY_TAX_NAME = 'County';
export const CRT_TAX = 'crt';
export const FLAT_TAX = 'flat';
export const LOCAL_TAX_NAME = 'Local';
export const MONTHLY_USE_TAXES_CATEGORY = 'Monthly Use Taxes';
export const MUT_TAX = 'mut';
export const STATE_TAX_NAME = 'State';
export const TAX_OVERRIDE = 'Tax Override';
export const TAX_OVERRIDE_DEBOUNCE_TIMER = 1200;
export const RATE = 'Rate';
export const TAX_AMOUNT = 'Tax Amount';
export enum TAX_ITEM_KEYS {
    STATE_TAX_RATE = 'stateTaxRate',
    COUNTY_TAX_RATE = 'countyTaxRate',
    CITY_TAX_RATE = 'cityTaxRate',
    LOCAL_TAX_RATE = 'localTaxRate'
}
export const TAXTABLECOLUMNS: SimpleTableHeadColumn[] = [
    { heading: NAME, options: { align: 'left' } },
    { heading: RATE, options: { align: 'center' } },
    { heading: TAX_AMOUNT, options: { align: 'right' } }
];

export const UPFRONT_TAXES_CATEGORY = 'Upfront Taxes';
