import { RSAA } from 'redux-api-middleware';

import type { StateTree } from '@makemydeal/dr-dash-types';
import { apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';
import {
    CONFIRM_PUSH_TO_ROUTEONE,
    PUSH_TO_ROUTEONE_FAILURE,
    PUSH_TO_ROUTEONE_REQUEST,
    PUSH_TO_ROUTEONE_SUCCESS,
    UPDATE_PUSH_TO_ROUTEONE_TIMESTAMP
} from '../actionTypes/pushToRouteOneActionTypes';
import { getDealXgId, getDealXgVersion, getDealRefIdFS, getCurrentOfferType } from '../selectors/offerRedux';
import { getSourcePartnerId, getSelectedCreditDecision } from '../selectors/creditDecisionSelectors';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

export const confirmPushToRouteOne = () => {
    return {
        type: CONFIRM_PUSH_TO_ROUTEONE
    };
};

export const updatePushToRouteOneTimeStamp = (timestamp: string, dealXgVersion: string) => {
    return {
        type: UPDATE_PUSH_TO_ROUTEONE_TIMESTAMP,
        payload: {
            timestamp,
            dealXgVersion
        }
    };
};

export const pushToRouteOneRSAA = (appPrefix: string, state: StateTree) => {
    const offerType = (state: StateTree) => {
        const value = getCurrentOfferType(state);
        if (value === 'finance') return 'Finance';
        else return 'Lease';
    };

    const dealRefId = getDealRefIdFS(state);
    const lenderId = getSelectedCreditDecision(state).lenderId;
    const dealXgId = getDealXgId(state);
    const dealXgVersion = getDealXgVersion(state);
    const financeMethod = offerType(state);
    const sourcePartnerId = getSourcePartnerId(state);
    const routeOnePartyId = dealerSelectors.getRouteOnePartyId(state);
    const targetPlatforms = [{ id: 'R1J', partyId: routeOnePartyId }];

    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, '/pushToFI');
    const endpoint = bffApiUrlResult.url;

    const body = JSON.stringify({
        dealRefId,
        lenderId,
        dealXgId,
        dealXgVersion,
        financeMethod,
        sourcePartnerId,
        targetPlatforms
    });

    return {
        [RSAA]: {
            endpoint,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body,
            types: [PUSH_TO_ROUTEONE_REQUEST, PUSH_TO_ROUTEONE_SUCCESS, PUSH_TO_ROUTEONE_FAILURE]
        }
    };
};
