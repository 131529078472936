import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BreakPoint } from '@makemydeal/dr-activities-common';
import { formatDollars } from '@makemydeal/dr-common-utils';
import { ManualIncentiveEntry } from '@makemydeal/dr-platform-types';

import { formatUtils } from '@makemydeal/dr-dash-components';
import {
    ManualIncentiveEditEntry,
    offerActionCreators,
    offerReduxSelectors,
    offerSelectors,
    transformIncentiveAmount,
    transformToEditIncentive
} from '@makemydeal/dr-dash-store';
import { INCENTIVE_TYPE_VALUES } from '@makemydeal/dr-dash-types';
import { useMediaQuery } from 'react-responsive';

import { RebatesEditProps } from './RebatesViewProps';

import { Button } from '@interstate/components/Button';
import { ArrowUturnLeftIcon, TrashIcon } from '@interstate/components/Icons';
import { SelectInput } from '@interstate/components/SelectInput';
import { TextInput } from '@interstate/components/TextInput';
import { Tooltip } from '@interstate/components/Tooltip';
import { Typography } from '@interstate/components/Typography';

import * as deskingConstants from '../../constants';

import { RebatesTotals } from './RebatesTotals.interstate';

import {
    AddIncentiveContainer,
    EditableRowsPlusAddButtonContainer,
    FlexManualRebatesButtonContainer,
    ManualRebatesFormContainer,
    ManualRebatesTypeDisplayContainer,
    RebateDeleteOrUndoContainer,
    RowGrid,
    TypeInputOrDisplayContainer
} from './RebatesEdit.interstate.styles';

import { manualIncentiveUtils } from '@makemydeal/dr-platform-shared';
import { getIncentiveDisplayType, isIncentiveFormValid, getErrorMessage } from './utils';
import { IncentivesAmoundField } from './RebatesAmountField.interstate';

const createNewManualIncentiveEntry = (): ManualIncentiveEditEntry => ({
    program: '',
    code: undefined,
    manual: true,
    name: '',
    description: '',
    type: '',
    amount: 0,
    deleted: false,
    originalAmount: 0,
    isStandardIncentive: false
});

const INCENTIVE_NOT_EDITED = -1;

export const RebatesEdit: FC<RebatesEditProps> = ({ setIncentivesMode }) => {
    const dispatch = useDispatch();
    const incentives = useSelector(offerReduxSelectors.getAppliedIncentives);
    const term = useSelector(offerSelectors.getSelectedTermMonths);

    const [haveIncentivesUpdated, setHaveIncentivesUpdated] = useState(false);
    const [incentivesToEdit, setIncentivesToEdit] = useState(transformToEditIncentive(term, incentives));
    const [isFormValid, setFormValid] = useState(false);

    // Current total for the edit screen (not including Dealer Cash incentives)
    const [currentEditedIncentivesTotal, setCurrentEditedIncentivesTotal] = useState(0);

    // Current (previously applied) total (not including Dealer Cash Incentives)
    const [currentAppliedIncentiveTotal, setCurrentAppliedIncentiveTotal] = useState(0);

    // Current dealer cash total
    const [dealerCashTotal, setDealerCashTotal] = useState(0);

    const dealerCashIncentives = incentivesToEdit.filter(manualIncentiveUtils.isDealerCash as any);

    const currentEditedIncentives = incentivesToEdit.filter(manualIncentiveUtils.isNotDealerCash as any);

    const CurrentAppliedIncentiveTotal = useSelector(offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash);

    const isWithinSmallViewport = useMediaQuery({ query: `(max-width: ${BreakPoint.SMALL})` });

    const messageErrorRef = useRef(0);

    const editedIncentiveIndexRef = useRef(INCENTIVE_NOT_EDITED);

    const codeCellErrorIndexesRef = useRef(new Set());
    const codeCellErrorIndexes = codeCellErrorIndexesRef.current;

    const handleIncentivesUpdate = () => {
        setHaveIncentivesUpdated(true);
        const newIncentives = incentivesToEdit.filter(({ deleted }) => !deleted);
        setIncentivesToEdit(newIncentives);
    };

    // Reset the error count to 0 on component load.
    useEffect(() => {
        messageErrorRef.current = 0;
        setCurrentEditedIncentivesTotal(manualIncentiveUtils.getIncentiveSum(currentEditedIncentives as any));
        setDealerCashTotal(manualIncentiveUtils.getIncentiveSum(dealerCashIncentives as any));
        setCurrentAppliedIncentiveTotal(CurrentAppliedIncentiveTotal);
    }, []);

    // Update the current total when incentives change
    useEffect(() => {
        if (!incentivesToEdit.length) {
            setIncentivesToEdit([createNewManualIncentiveEntry()]);
        }
        setCurrentEditedIncentivesTotal(manualIncentiveUtils.getIncentiveSum(currentEditedIncentives as any));
        setDealerCashTotal(manualIncentiveUtils.getIncentiveSum(dealerCashIncentives as any));
        setFormValid(isIncentiveFormValid(incentivesToEdit));
    }, [incentivesToEdit]);

    // When the Update Offer button is clicked, dispatch action to update offer and move to view mode, now showing the updates applied.
    useEffect(() => {
        if (haveIncentivesUpdated) {
            dispatch(
                offerActionCreators.updateManualIncentives({
                    manualIncentives: incentivesToEdit,
                    dealerCashTotal,
                    totalRebates: currentEditedIncentivesTotal
                })
            );
            setIncentivesMode('view');
        }
    }, [haveIncentivesUpdated]);

    return (
        <>
            <EditableRowsPlusAddButtonContainer>
                {/* TODO: add US1265956 typography once figma dev mode access is back */}
                <Typography variant="label-md" sx={{ marginBottom: '16px' }} color="base.color.gray.600">
                    Program number and code fields are optional.
                </Typography>
                <ManualRebatesFormContainer>
                    {incentivesToEdit.map((incentive, index: number) => {
                        const { amount, manual, deleted, name: rebateName, program, code, type, description } = incentive;

                        const columnMappings = {
                            'Program #': {
                                fieldName: 'program',
                                value: program,
                                required: false
                            },
                            'Incentive Name': {
                                fieldName: 'name',
                                value: rebateName,
                                required: true
                            },
                            Code: {
                                fieldName: 'code',
                                value: code,
                                required: false
                            },
                            Type: {
                                fieldName: 'type',
                                value: type,
                                required: true
                            },
                            Amount: {
                                fieldName: 'amount',
                                value: transformIncentiveAmount(term, incentive),
                                required: true
                            }
                        };

                        // This is the only way we can identify incentives that came from program-incentives
                        const isFromProgramIncentives = description && description !== rebateName;

                        const handleIncentiveDeleteOrUndo = (event: any) => {
                            messageErrorRef.current = 0;
                            setHaveIncentivesUpdated(false);
                            const deletedIncentives = incentivesToEdit.map((incentive, currentIndex) => {
                                if (currentIndex === index) {
                                    incentive.deleted = !incentive.deleted;
                                }
                                return incentive;
                            });
                            setIncentivesToEdit(deletedIncentives);
                            editedIncentiveIndexRef.current = index;
                        };

                        return (
                            <>
                                {index > 0 && <hr />}
                                <RowGrid data-testid={`incentive-row-${index}`}>
                                    <RebateDeleteOrUndoContainer
                                        data-testid={`manual-rebate-trash-icon-${index}`}
                                        onClick={handleIncentiveDeleteOrUndo}
                                    >
                                        {deleted ? (
                                            <>
                                                {(isWithinSmallViewport || index === 0) && (
                                                    <label data-testid={`undo-${index}`}>&nbsp;</label>
                                                )}
                                                <Button
                                                    buttonStyle="secondary"
                                                    icon={
                                                        <Tooltip
                                                            position="bottom"
                                                            toolTipContent="Item was deleted. You can still undo this before updating the Offer."
                                                            size="large"
                                                        >
                                                            <ArrowUturnLeftIcon color="#005BA8" />
                                                        </Tooltip>
                                                    }
                                                    size="medium"
                                                />
                                            </>
                                        ) : (
                                            <>
                                                {(isWithinSmallViewport || index === 0) && (
                                                    <label data-testid={`delete-${index}`}>&nbsp;</label>
                                                )}
                                                <Button
                                                    buttonStyle="secondary"
                                                    icon={
                                                        <TrashIcon data-testid={`manual-rebate-delete-${index}`} color="#005BA8" />
                                                    }
                                                    size="medium"
                                                />
                                            </>
                                        )}
                                    </RebateDeleteOrUndoContainer>
                                    {Object.entries(columnMappings).map(
                                        ([incentiveLabel, { value: fieldValue, fieldName, required }], columnIndex) => {
                                            const handleInputChange = (event: any) => {
                                                messageErrorRef.current = 0;

                                                editedIncentiveIndexRef.current = index;

                                                setHaveIncentivesUpdated(false);
                                                let textValue: string | number;
                                                switch (incentiveLabel) {
                                                    case deskingConstants.CODE:
                                                        textValue =
                                                            event.target.value !== ''
                                                                ? formatUtils.convertToNumber(event.target.value)
                                                                : '';
                                                        break;
                                                    default:
                                                        textValue = event.target.value;
                                                }

                                                setIncentivesToEdit((incentivesToEdit) => {
                                                    return incentivesToEdit.reduce(
                                                        (newIncentives, currentIncentive, currentIndex) => {
                                                            const currentIncentiveCopy: any = { ...currentIncentive };

                                                            if (index === currentIndex) {
                                                                if (currentIncentiveCopy.originalAmount !== textValue) {
                                                                    currentIncentiveCopy.manual = true;
                                                                }
                                                                currentIncentiveCopy[fieldName] = textValue;
                                                            }

                                                            newIncentives[currentIndex] = currentIncentiveCopy;
                                                            return newIncentives;
                                                        },
                                                        [] as any
                                                    );
                                                });
                                            };

                                            let errorDisplay = '';
                                            if (
                                                !deleted &&
                                                incentiveLabel === deskingConstants.CODE &&
                                                (index === editedIncentiveIndexRef.current || codeCellErrorIndexes.has(index))
                                            ) {
                                                const isUnique = !incentivesToEdit.find((val, ind) => {
                                                    return ind !== index && val.code && val.code == code && !val.deleted;
                                                });
                                                if (!isUnique) {
                                                    errorDisplay = deskingConstants.INCENTIVE_DUPLICATE_CODE;
                                                    !codeCellErrorIndexes.has(index) && codeCellErrorIndexes.add(index);
                                                } else {
                                                    codeCellErrorIndexes.delete(index);
                                                }
                                            }

                                            if (errorDisplay) {
                                                messageErrorRef.current++;
                                            }

                                            if (incentiveLabel === deskingConstants.TYPE) {
                                                const selectOptions = [
                                                    INCENTIVE_TYPE_VALUES.dealerCash,
                                                    INCENTIVE_TYPE_VALUES.customerCash
                                                ];
                                                const typeInputOrDisplay =
                                                    manual && !isFromProgramIncentives ? (
                                                        <>
                                                            {/* TODO: US1265956 add label typography once figma dev mode access is back */}
                                                            {(isWithinSmallViewport || index === 0) && (
                                                                <Typography
                                                                    variant="label-md"
                                                                    color="base.color.gray.600"
                                                                    sx={{ marginBottom: '6px' }}
                                                                >
                                                                    {incentiveLabel}
                                                                </Typography>
                                                            )}
                                                            <SelectInput
                                                                name={`type-${index}`}
                                                                data-testid={`type-${index}`}
                                                                onChange={handleInputChange}
                                                                options={selectOptions}
                                                                size="medium"
                                                                displayLabel={false}
                                                                errorMessage={getErrorMessage(incentivesToEdit[index].type)}
                                                                hasError={haveIncentivesUpdated && !incentivesToEdit[index].type}
                                                                value={fieldValue as string}
                                                                required={required && !deleted}
                                                                disabled={deleted}
                                                                displayDeselectOption={false}
                                                            />
                                                        </>
                                                    ) : (
                                                        <ManualRebatesTypeDisplayContainer key={`${index}-${columnIndex}`}>
                                                            {(isWithinSmallViewport || index === 0) && (
                                                                <div data-testid={`type-label-${index}`} className="type-display">
                                                                    {incentiveLabel}
                                                                </div>
                                                            )}
                                                            <div className="type-value">
                                                                {getIncentiveDisplayType(incentive as ManualIncentiveEntry)}
                                                            </div>
                                                        </ManualRebatesTypeDisplayContainer>
                                                    );
                                                return (
                                                    <TypeInputOrDisplayContainer>{typeInputOrDisplay}</TypeInputOrDisplayContainer>
                                                );
                                            } else {
                                                return (
                                                    <div className={`text-input-${fieldName}`} key={`${index}-${columnIndex}`}>
                                                        {/* TODO: US1265956 add label typography once figma dev mode access is back */}
                                                        {(isWithinSmallViewport || index === 0) && (
                                                            <Typography
                                                                variant="label-md"
                                                                color="base.color.gray.600"
                                                                sx={{ marginBottom: '6px' }}
                                                            >
                                                                {incentiveLabel}
                                                            </Typography>
                                                        )}
                                                        {fieldName === 'amount' ? (
                                                            <IncentivesAmoundField
                                                                fieldName={fieldName}
                                                                index={index}
                                                                handleInputChange={handleInputChange}
                                                                errorDisplay={errorDisplay}
                                                                required={required}
                                                                deleted={deleted}
                                                                fieldValue={fieldValue}
                                                            />
                                                        ) : (
                                                            <TextInput
                                                                displayLabel={false}
                                                                data-testid={`${fieldName}-${index}`}
                                                                name={`${fieldName}-${index}`}
                                                                onChange={handleInputChange}
                                                                value={fieldValue?.toString()}
                                                                errorMessage={errorDisplay}
                                                                hasError={!!errorDisplay}
                                                                required={required}
                                                                disabled={deleted}
                                                                size="medium"
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            }
                                        }
                                    )}
                                </RowGrid>
                            </>
                        );
                    })}
                </ManualRebatesFormContainer>

                <AddIncentiveContainer>
                    <Button
                        buttonStyle="tertiary"
                        data-testid="add-incentives-button"
                        onClick={() => {
                            setHaveIncentivesUpdated(false);
                            setIncentivesToEdit([...incentivesToEdit, createNewManualIncentiveEntry()]);
                        }}
                        size="small"
                    >
                        + Add Incentive
                    </Button>
                </AddIncentiveContainer>
            </EditableRowsPlusAddButtonContainer>

            <RebatesTotals
                dealerCashTotal={dealerCashTotal}
                currentAppliedIncentiveTotal={currentAppliedIncentiveTotal}
                currentEditedIncentivesTotal={currentEditedIncentivesTotal}
                showEditTotals
                showRebatesTotal
            />

            <FlexManualRebatesButtonContainer id="manual-rebates-button-container">
                <Button
                    data-testid="btn-manual-rebates-cancel-edit"
                    buttonStyle="secondary"
                    onClick={() => {
                        setIncentivesMode('view');
                    }}
                    size="medium"
                >
                    Cancel
                </Button>
                <Button
                    data-testid="btn-manual-rebates-edit-update-offer"
                    buttonStyle="primary"
                    onClick={handleIncentivesUpdate}
                    disabled={editedIncentiveIndexRef.current === INCENTIVE_NOT_EDITED || !!messageErrorRef.current || !isFormValid}
                    size="medium"
                >
                    Update Offer
                </Button>
            </FlexManualRebatesButtonContainer>
        </>
    );
};
