import React from 'react';

// library
import { LoadingIndicator as InterstateLoadingIndicator } from '@interstate-beta/components/LoadingIndicator';

// style
import { Overlay, OverlayContent } from './LoadingSection.style';

// types
import { IOverlayProps } from './LoadingSectionTypes';

const id = 'loading-section';

/**
 * Simple loading indicator element that blocks the sibling content below it with a translucent background
 * Must have a LoadingSectionParent element as its direct parent element
 */
const LoadingSection = ({ loading, testId }: IOverlayProps) => {
    return (
        <Overlay loading={loading} data-testid={testId || id}>
            <OverlayContent>
                <InterstateLoadingIndicator data-testid={`payment-${id}`} size="default" />
            </OverlayContent>
        </Overlay>
    );
};

export default LoadingSection;
