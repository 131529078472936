import { ADDITIONAL_QUERY_PARAMS_WHITE_LIST } from '@makemydeal/dr-shared-components';
import { offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';

export const getABPencilToggle = () => {
    offerSessionUtils.parseAndWriteOfferIdentifiers(window.location.href);
    offerSessionUtils.parseAndWriteAdditionalToggles(window.location.href, { whitelist: ADDITIONAL_QUERY_PARAMS_WHITE_LIST });

    const { enableToggles } = offerSessionUtils.getAdditionalToggles();
    const toggles = (enableToggles || '').split(',');
    const enabled = Boolean(toggles.find((t) => t === 'enableABPencilStateMV'));

    return enabled;
};
