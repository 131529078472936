// externals
import { useDispatch, useSelector } from 'react-redux';

// store
import { offerReduxActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';

// components
import { ToggleSwitch, ToggleSwitchProps } from '@interstate-beta/components/ToggleSwitch';

import { StyledOfficialCreditDecisionToggle } from './OfficialCreditDecisionToggle.style';
import { CREDIT_DECISION_TOGGLE } from '../../constants';

export interface OfficialCreditDecisionToggleProps {
    disabled: boolean;
}

export const OfficialCreditDecisionToggle = ({ disabled }: OfficialCreditDecisionToggleProps) => {
    const dispatch = useDispatch();
    const isCreditDecisionEnabled = useSelector(offerReduxSelectors.getCreditDecisionEnabled) as boolean;

    const onToggleSwitchClick: ToggleSwitchProps['onClick'] = (event) => {
        const isChecked = event.target.value === 'checked';
        dispatch(offerReduxActionCreators.updateCreditDecisionEnabled(isChecked));
    };

    return (
        <StyledOfficialCreditDecisionToggle>
            <ToggleSwitch
                layout="horizontal"
                disabled={disabled}
                label={CREDIT_DECISION_TOGGLE}
                data-testid={'official-credit-decision-toggle'}
                checked={isCreditDecisionEnabled}
                onClick={onToggleSwitchClick}
            />
        </StyledOfficialCreditDecisionToggle>
    );
};
