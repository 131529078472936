// externals
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// libraries
import { Button } from '@interstate-beta/components/Button';
import { ArrowTopRightOnSquareIcon } from '@interstate-beta/components/Icons';
import { configSelectors, navigationHooks, offerReduxSelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors, dealerSelectors } from '@makemydeal/dr-shared-store';
import { HostEnvironment, MANAGER_VIEW_APP_PREFIX, hostUrlHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';

// styles
import {
    CreditAndFinanceTitle,
    CreditAndFinanceWrapper,
    CreditBureauContainer,
    LenderDecisionContainer,
    CreditAndFinanceTitleWrapper,
    CreditApplicant
} from './CreditDecision.style';

// components
import LenderDecisionInfo from './LenderDecisionInfo';

export const CreditDecision = () => {
    const toggleEnableLenderDecisionsInCreditBureau = useSelector(featureToggleSelectors.isEnableLenderDecisionsInCreditBureau);
    const shopper = useSelector(offerReduxSelectors.getShopperInfo);
    const { firstName, lastName } = shopper;

    return (
        <>
            <CreditBureauContainer data-testid="credit-decision-interstate-container">
                <CreditAndFinanceWrapper>
                    <CreditAndFinanceTitleWrapper>
                        <CreditAndFinanceTitle data-testid="credit-and-finance-title">Credit Decisions</CreditAndFinanceTitle>
                        <CreditApplicant data-testid="applicant">
                            Applicant: {firstName} {lastName}
                        </CreditApplicant>
                    </CreditAndFinanceTitleWrapper>
                    {toggleEnableLenderDecisionsInCreditBureau && (
                        <LenderDecisionContainer data-testid="lender-decision-container">
                            <LenderDecisionInfo data-testid="lender-decision-layout" />
                        </LenderDecisionContainer>
                    )}
                </CreditAndFinanceWrapper>
            </CreditBureauContainer>
        </>
    );
};
