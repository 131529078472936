import { Box } from '@interstate-beta/components/Box';
import { Typography } from '@interstate-beta/components/Typography';
import { memo } from 'react';

export const ManualLegendMessage = memo(() => (
    <Box borderLeft="4px solid #ebb000" padding="0 0.6rem" data-testid="tax-override-message">
        <Typography variant="body-sm" color="base.color.gray.700" className={'manual-'} data-testid="manually-edited-notification">
            Manually Edited
        </Typography>
    </Box>
));
