// externals
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import {
    LoadingSectionParent,
    PaymentLoading,
    usePaymentCall,
    FlexGridCol,
    FlexCol,
    FlexRow,
    ShowWithFeatureToggle,
    StyledVAutoAppraisalStatus
} from '@makemydeal/dr-dash-components';
import { Card, CardHeader } from '@interstate/components/Card';
import { DatePicker } from '@interstate/components/DatePicker';
import { TextInput } from '@interstate/components/TextInput';
import { GetError, ValidationStateType } from '@interstate/components/Validation/Types/validationTypes';
import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';
import { ArrowTopRightOnSquareIcon } from '@interstate/components/Icons';
import { Badge, BadgeVariant } from '@interstate/components/Badge';
import { CheckBox, CheckBoxEventValue } from '@interstate/components/CheckBox';
import { InterstateOnChangeCallback, InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { Alert } from '@interstate/components/Alert';
import TriforceWidget from './triforceWidget/TriforceWidget';

// styling
import { DetailContainer, TradeCheckboxContainer, TradeInSeparator, VehicleDetailContent } from './trade.interstate.style';

// libraries
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';

// utils
import { isAlphaNumericWithSpaces, isNaNish } from '../../utils/formatUtils';

// selectors
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import {
    configSelectors,
    tradeInSelectors,
    tradeShopperActivity,
    deskingSelectors,
    offerReduxSelectors,
    vAutoSelectors,
    tradeActionTypes,
    vAutoTriforceSelectors
} from '@makemydeal/dr-dash-store';

// constants
import {
    ACV,
    ALLOWANCE,
    GENERIC_LAST_FIELD_CHANGED_ERROR,
    LIEN_HOLDER,
    LEASE,
    LEASE_TRADE,
    INCLUDE_TRADE_WITH_OFFER,
    PAYOFF,
    PAYOFF_DUE_DATE,
    PER_DIEM,
    IS_BUYER_REGISTERED_OWNER,
    VEHICLE_IS_REGISTERED_IN_SAME_STATE
} from '../../constants';

// actions
import { tradeInActionCreators } from '@makemydeal/dr-dash-store';

import ManualTradeIn from './manualTradeIn/ManualTradeIn.interstate';
import { VAutoAppraisalStatus } from '@makemydeal/dr-dash-types';

import LienholderForm from './lienholderForm/LienholderForm.interstate';
import { NumericInput } from '@interstate/components/NumericInput';

const IS_BUYER_REGISTERED_OWNER_ID = 'is-buyer-registered-owner';
const VEHICLE_IS_REGISTERED_IN_SAME_STATE_ID = 'vehicle-is-registered-in-same-state';

const TradeCard = () => {
    const dispatch = useDispatch();
    const isTradeActivityEnabledAfterBootstrap = useSelector(featureToggleSelectors.isTradeActivityEnabledAfterBootstrap);
    const useEnhancedTradeInMV = useSelector(featureToggleSelectors.useEnhancedTradeInMV);

    const isTradeInCompleted = useSelector(tradeInSelectors.isTradeInCompleted);
    const payoffDueDateFromSelector = useSelector(tradeInSelectors.getTradePayoffDueDate);
    const payoffDueDate: Date | null = payoffDueDateFromSelector ? new Date(payoffDueDateFromSelector) : null;

    const lienHolder = useSelector(tradeInSelectors.getLienholder);

    const vin = useSelector(tradeInSelectors.getVin);
    const showVinDecodeError = useSelector(tradeInSelectors.getErrorDecodingVin);
    const isManualFormOpen = useSelector(tradeInSelectors.isManualFormOpen);
    const ownershipType = useSelector(tradeInSelectors.getTradeOwnershipType);
    const isLeaseTrade = ownershipType === LEASE ? true : false;
    const vehicleYear = useSelector(tradeInSelectors.getTradeYear);
    const vehicleMake = useSelector(tradeInSelectors.getTradeMake);
    const vehicleModel = useSelector(tradeInSelectors.getTradeModel);
    const vehicleTrim = useSelector(tradeInSelectors.getTradeTrim);
    const vehicleMileage = useSelector(tradeInSelectors.getTradeMileage);
    const isVAutoEnabled = useSelector(dealerSelectors.isVAutoAppraisalEnabled);
    const useCoOrgIdInVAutoAppraisalUrl = useSelector(featureToggleSelectors.useCoOrgIdInVAutoAppraisalUrl);
    const commonOrgId = useSelector(offerReduxSelectors.getCommonOrgId);
    const currAppraisal = useSelector(vAutoSelectors.getCurrentAppraisalFromVin);
    const vAutoAppraisalUrl = useSelector(configSelectors.getVAutoAppraisalUrl);
    const vAutoAppraisalStatus = currAppraisal?.status;
    const vAutoAppraisalAcv = currAppraisal?.value;
    const failedAllowance = useSelector(deskingSelectors.wasTheLastFailure(tradeActionTypes.ALLOWANCE_CHANGED));

    const failedPayoff = useSelector(deskingSelectors.wasTheLastFailure(tradeActionTypes.PAYOFF_CHANGED));

    const failedACV = useSelector(deskingSelectors.wasTheLastFailure(tradeActionTypes.ACV_CHANGED));

    const isBuyerRegisteredOwner = useSelector(tradeInSelectors.getIsBuyerRegisteredOwner);
    const isSellingVehicleRegisteredInSameState = useSelector(tradeInSelectors.getIsSellingVehicleRegisteredInSameState);
    const shouldIncludeTradeIn = useSelector(tradeInSelectors.getShouldIncludeTradeIn);
    const includeTradeChecked = shouldIncludeTradeIn ? 'includeTrade' : '';

    const vAutoTriforceWidgetData = useSelector(vAutoTriforceSelectors.getVAutoTriforceData);
    const vAutoTriforceScriptUrl = useSelector(configSelectors.getVAutoTriforceScriptUrl);

    const handleTrade = () => {
        if (isTradeActivityEnabledAfterBootstrap) {
            dispatch(tradeInActionCreators.toggleManualTradeForm());
        }
    };

    const handleCancelClick = () => {
        dispatch(tradeInActionCreators.tradeDetailsManualEntryCancel());
        dispatch(tradeInActionCreators.toggleManualTradeForm());
    };

    const handleSaveClick = () => {
        dispatch(tradeInActionCreators.tradeDetailsManualEntrySave());
        dispatch(tradeInActionCreators.toggleManualTradeForm());
    };

    const {
        value: allowance,
        handleChange: changeAllowance,
        handleBlur: allowanceBlur
    } = usePaymentCall(tradeInSelectors.getTradeValue, tradeShopperActivity.tradeActionCreators.allowanceChanged, {
        formatValueToString: formatDollarsAndCents,
        ignoreTextValue: () => false
    });

    const {
        value: payoff,
        handleChange: changePayoff,
        handleBlur: payoffBlur
    } = usePaymentCall(tradeInSelectors.getTradeInAmountOwed, tradeShopperActivity.tradeActionCreators.payoffChanged, {
        formatValueToString: formatDollarsAndCents,
        ignoreTextValue: () => false
    });

    const {
        value: acv,
        handleChange: changeAcv,
        handleBlur: acvBlur
    } = usePaymentCall(tradeInSelectors.getTradeACV, tradeShopperActivity.tradeActionCreators.acvChanged, {
        formatValueToString: formatDollarsAndCents,
        ignoreTextValue: () => false
    });

    const {
        value: perDiem,
        handleChange: onPerDiemChange,
        handleBlur: perDiemBlur
    } = usePaymentCall(tradeInSelectors.getPerDiem, tradeInActionCreators.updateTradeInPerDiem, {
        formatValueToString: formatDollarsAndCents,
        ignoreTextValue: () => false
    });

    const onLienholderChange = (event: any) => {
        dispatch(tradeInActionCreators.updateTradeInLienholder(event.target.value));
    };

    const handleInvalidLienholder: GetError = (label, value) => {
        const validInputState = {
            triggered: true,
            validationState: {
                isValid: true,
                type: 'success' as ValidationStateType
            }
        };
        if (!value) {
            return validInputState;
        }
        return isAlphaNumericWithSpaces(value)
            ? validInputState
            : {
                  triggered: true,
                  validationState: {
                      isValid: false,
                      message: `${label} must be alphanumeric`,
                      type: 'error'
                  }
              };
    };

    /* istanbul ignore next */
    const onPayoffDueDateChange = (event: any) => {
        const isDateValueIncluded = event.target.value?.dateValue;
        dispatch(tradeInActionCreators.updateTradeInPayoffDueDate(isDateValueIncluded));
    };

    const getTradeInTitle = (): string => {
        if (isTradeInCompleted && !isManualFormOpen) {
            return 'View/Edit';
        } else if (!isTradeInCompleted && !isManualFormOpen) {
            return 'Add';
        } else {
            return '';
        }
    };

    const handleIsBuyerRegisteredOwnerChange: InterstateOnChangeCallback<CheckBoxEventValue> = (event) => {
        /* istanbul ignore next */
        const checked = Boolean(event.target.value?.checked);
        dispatch(tradeInActionCreators.updateTradeInIsBuyerRegisteredOwner(checked));
    };

    const handleIsSellingVehicleRegisteredInSameStateChange: InterstateOnChangeCallback<CheckBoxEventValue> = (event) => {
        /* istanbul ignore next */
        const checked = Boolean(event.target.value?.checked);
        dispatch(tradeInActionCreators.updateTradeInIsSellingVehicleRegisteredInSameState(checked));
    };

    const handleCheckboxChange: InterstateOnChangeCallback<CheckBoxEventValue> = (
        event: InterstateOnChangeEvent<CheckBoxEventValue>
    ) => {
        /* istanbul ignore next */
        const isTradeInIncluded = event.target.value?.checked;
        dispatch(tradeInActionCreators.includeTradeChanged(!!isTradeInIncluded));
    };

    const handleAppraisalClick = (isAppraisalAvailable: boolean) => {
        const appraisalPayload = JSON.stringify({ Vin: vin, Odometer: vehicleMileage });
        let url = `${vAutoAppraisalUrl}?${
            useCoOrgIdInVAutoAppraisalUrl ? `CommonOrgId=${commonOrgId}&` : ''
        }SelectedExitStrategy=Retail&InitialData=${appraisalPayload}`;
        if (isAppraisalAvailable) {
            url = `${vAutoAppraisalUrl}?${useCoOrgIdInVAutoAppraisalUrl ? `CommonOrgId=${commonOrgId}&` : ''}Id=${
                currAppraisal.id
            }&AppraisalStatus=${vAutoAppraisalStatus}`;
        }
        window.open(url);
    };

    const getAppraisalStatusTitle = () => {
        if (isVAutoEnabled) {
            let variant: BadgeVariant = 'info';
            let message = '';
            let isAppraisalAvailable = false;
            if (vAutoAppraisalStatus === VAutoAppraisalStatus.InProcess) {
                variant = 'caution';
                message = 'Appraisal in Progress';
                isAppraisalAvailable = true;
            } else if (vAutoAppraisalStatus === VAutoAppraisalStatus.Completed) {
                variant = 'success';
                message = 'Appraised';
                isAppraisalAvailable = true;
            }
            const acvUpdateAlert = isAppraisalAvailable && vAutoAppraisalAcv > 0 && (
                <Alert displayCloseButton data-testid="vauto-appraisal-acv-alert" role="banner" type="info">
                    <Typography variant="body-sm" tag="span">{`ACV was updated to ${formatDollarsAndCents(
                        vAutoAppraisalAcv
                    )}`}</Typography>
                </Alert>
            );
            const badge = (
                <StyledVAutoAppraisalStatus data-testid="vauto-status-badge" variant={variant}>
                    {message}
                </StyledVAutoAppraisalStatus>
            );
            const link = (
                <Action
                    data-testid="vAuto-link"
                    actionIcon={{
                        icon: <ArrowTopRightOnSquareIcon />,
                        end: true
                    }}
                    onClick={() => handleAppraisalClick(isAppraisalAvailable)}
                >
                    <Typography variant="anchor-block-sm" color="base.color.blue.700">
                        {isAppraisalAvailable ? 'View in vAuto' : 'Start Appraisal'}
                    </Typography>
                </Action>
            );
            return (
                <FlexCol>
                    {acvUpdateAlert}
                    <FlexRow justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Valuation {isAppraisalAvailable && badge}</Typography>
                        {link}
                    </FlexRow>
                </FlexCol>
            );
        }
        return (
            <FlexCol>
                <FlexRow justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Valuation</Typography>
                </FlexRow>
            </FlexCol>
        );
    };

    return (
        <LoadingSectionParent>
            <PaymentLoading testId="trade-loading-indicator" />
            <Card
                data-testid="trade-card"
                header={
                    <CardHeader
                        title={<Typography variant="h5">Trade-In</Typography>}
                        action={
                            <Action onClick={handleTrade} data-testid="trade-card-edit-action">
                                <Typography variant="anchor-block-sm" color="base.color.blue.700">
                                    {getTradeInTitle()}
                                </Typography>
                            </Action>
                        }
                    />
                }
                content={
                    <FlexCol>
                        {showVinDecodeError && (
                            <Alert type="error" role="alert">
                                <Typography variant="body-sm" tag="span">
                                    There was a problem decoding the VIN. Please try again.
                                </Typography>
                            </Alert>
                        )}
                        {isLeaseTrade && !isManualFormOpen && (
                            <Badge sx={{ width: 'fit-content' }}>
                                <Typography variant="body-sm" tag="span">
                                    {LEASE_TRADE}
                                </Typography>
                            </Badge>
                        )}
                        {!isManualFormOpen && (
                            <>
                                {isTradeInCompleted ? (
                                    <VehicleDetailContent data-testid="vehicle-detail-content">
                                        <DetailContainer>
                                            <Typography
                                                variant="body-md"
                                                tag="span"
                                                data-testid="trade-card-subtitle"
                                            >{`${vehicleYear} ${vehicleMake} ${vehicleModel} ${vehicleTrim}`}</Typography>
                                            {vin && (
                                                <Typography variant="body-md" tag="span" data-testid="trade-card-subtitle-vin">
                                                    {`VIN: ${vin}`}
                                                </Typography>
                                            )}
                                        </DetailContainer>
                                        {vAutoTriforceWidgetData && (
                                            <TriforceWidget
                                                displayData={vAutoTriforceWidgetData}
                                                scriptUrl={vAutoTriforceScriptUrl}
                                            />
                                        )}
                                    </VehicleDetailContent>
                                ) : (
                                    <Typography variant="body-md" tag="div">
                                        No vehicle information
                                    </Typography>
                                )}
                            </>
                        )}
                        <ManualTradeIn
                            cancel={handleCancelClick}
                            save={handleSaveClick}
                            isManualFormOpen={isManualFormOpen}
                            hasTradeIn={isTradeInCompleted}
                        />
                        {isManualFormOpen && <TradeInSeparator />}
                        <FlexGridCol cols={1}>{getAppraisalStatusTitle()}</FlexGridCol>
                        <FlexGridCol cols={2} smCols={3} alignItems="start">
                            <NumericInput
                                inputPrefix={'$'}
                                autoInsertCommas
                                allowDecimal
                                decimalMaxLength={2}
                                decimalMinLength={2}
                                data-testid="allowance"
                                label={ALLOWANCE}
                                name={kebabCase(ALLOWANCE)}
                                onChange={changeAllowance}
                                placeholder="10,000.00"
                                value={allowance}
                                hasError={failedAllowance}
                                errorMessage={GENERIC_LAST_FIELD_CHANGED_ERROR}
                                onBlur={allowanceBlur}
                                disabled={!isTradeInCompleted}
                            />
                            <NumericInput
                                inputPrefix={'$'}
                                autoInsertCommas
                                allowDecimal
                                decimalMaxLength={2}
                                decimalMinLength={2}
                                data-testid="acv"
                                label={ACV}
                                name={kebabCase(ACV)}
                                onChange={changeAcv}
                                placeholder="10,000.00"
                                value={vAutoAppraisalAcv > 0 ? formatDollarsAndCents(vAutoAppraisalAcv) : acv}
                                onBlur={acvBlur}
                                hasError={failedACV}
                                errorMessage={GENERIC_LAST_FIELD_CHANGED_ERROR}
                                disabled={!isTradeInCompleted || !!vAutoAppraisalStatus}
                            />
                            <NumericInput
                                inputPrefix={'$'}
                                autoInsertCommas
                                allowDecimal
                                decimalMaxLength={2}
                                decimalMinLength={2}
                                data-testid="payoff"
                                label={PAYOFF}
                                name={kebabCase(PAYOFF)}
                                onChange={changePayoff}
                                placeholder="10,000.00"
                                value={payoff}
                                hasError={failedPayoff}
                                errorMessage={GENERIC_LAST_FIELD_CHANGED_ERROR}
                                onBlur={payoffBlur}
                                disabled={!isTradeInCompleted}
                            />
                            {!useEnhancedTradeInMV && (
                                <>
                                    <DatePicker
                                        id="payoff-due-date-input"
                                        data-testid="payoff-due-date-input"
                                        label={PAYOFF_DUE_DATE}
                                        value={payoffDueDate as Date}
                                        onChange={onPayoffDueDateChange}
                                        disabled={!isTradeInCompleted}
                                    />
                                    <NumericInput
                                        inputPrefix={'$'}
                                        autoInsertCommas
                                        allowDecimal
                                        decimalMaxLength={2}
                                        decimalMinLength={2}
                                        data-testid="per-diem"
                                        label={PER_DIEM}
                                        name={kebabCase(PER_DIEM)}
                                        onChange={onPerDiemChange}
                                        placeholder="0.00"
                                        value={perDiem}
                                        onBlur={perDiemBlur}
                                        disabled={!isTradeInCompleted}
                                    />
                                    <TextInput
                                        data-testid="lienholder"
                                        label={LIEN_HOLDER}
                                        name={kebabCase(LIEN_HOLDER)}
                                        onChange={onLienholderChange}
                                        placeholder="Wells Fargo"
                                        value={lienHolder}
                                        getError={handleInvalidLienholder}
                                        disabled={!isTradeInCompleted}
                                    />
                                </>
                            )}
                        </FlexGridCol>
                        <ShowWithFeatureToggle name="useEnhancedTradeInMV">
                            <LienholderForm />
                        </ShowWithFeatureToggle>
                        <ShowWithFeatureToggle name="enableTradeInRegistrationFields">
                            <FlexCol>
                                <CheckBox
                                    checked={isBuyerRegisteredOwner}
                                    data-testid={IS_BUYER_REGISTERED_OWNER_ID}
                                    id={IS_BUYER_REGISTERED_OWNER_ID}
                                    label={IS_BUYER_REGISTERED_OWNER}
                                    onChange={handleIsBuyerRegisteredOwnerChange}
                                    value={IS_BUYER_REGISTERED_OWNER_ID}
                                    disabled={!isTradeInCompleted}
                                />
                                <CheckBox
                                    checked={isSellingVehicleRegisteredInSameState}
                                    label={VEHICLE_IS_REGISTERED_IN_SAME_STATE}
                                    onChange={handleIsSellingVehicleRegisteredInSameStateChange}
                                    value={VEHICLE_IS_REGISTERED_IN_SAME_STATE_ID}
                                    disabled={!isTradeInCompleted}
                                />
                            </FlexCol>
                        </ShowWithFeatureToggle>
                        <TradeInSeparator />
                        <TradeCheckboxContainer>
                            <CheckBox
                                checked={shouldIncludeTradeIn}
                                label={INCLUDE_TRADE_WITH_OFFER}
                                onChange={handleCheckboxChange}
                                value={includeTradeChecked}
                                disabled={!isTradeInCompleted}
                            />
                            <Typography variant="body-xxs" tag="div">
                                *Vehicle information is required to include the trade with the offer.
                            </Typography>
                        </TradeCheckboxContainer>
                    </FlexCol>
                }
            />
        </LoadingSectionParent>
    );
};

export default TradeCard;
