// externals
import { useSelector } from 'react-redux';

// libraries
import { capitalize, isValidNumber } from '@makemydeal/dr-common-utils';
import type { DealHistory, StateTree } from '@makemydeal/dr-dash-types';
import { dealEffectiveSelectors } from '@makemydeal/dr-dash-store';

// consts/enums
import { NOT_AVAILABLE } from '../../constants';

// components
import DealSummaryItem from '../common/DealSummaryItem';

const TermLength = ({ deal, hasDelta = false }: { deal?: DealHistory; hasDelta?: boolean }) => {
    const offerType = useSelector((state: StateTree) => dealEffectiveSelectors.selectEffectivetOfferType(state, deal));
    const termMonths = useSelector((state: StateTree) => dealEffectiveSelectors.getEffectiveSelectedTermMonths(state, deal));

    const value = termMonths != null && isValidNumber(termMonths) ? `${termMonths} Months` : NOT_AVAILABLE;
    const label = `${capitalize(offerType)} Term`;

    return <DealSummaryItem label={label} value={value} hasDelta={hasDelta} />;
};

export default TermLength;
