/* eslint-disable @typescript-eslint/ban-types */
import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';
import { getMaxAppContainerWidth } from '../utils/getMaxAppContainerWidth';

export const HeaderRoot = styled.div`
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
`;

export const HeaderContainer = styled.div`
    background-color: #ffffff;
`;

export const HeaderPanelsRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: ${BreakPoint.SMALL}) {
        padding: 0 1rem;
        flex-direction: row;
    }
`;

export const HeaderRow = styled.div<{ isNewAppWidthEnabled: boolean }>`
    display: flex;
    flex-direction: row;

    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin: 0 auto;
    padding: 0.5rem 1rem 0.875rem 1rem;
    border-bottom: 0.3rem solid #005ba8;

    img {
        display: none;

        @media (min-width: ${BreakPoint.PHONE}) {
            display: block;
            width: 8.4rem;
            height: 6.3rem;
            margin-right: 1rem;
        }
    }
`;

export const DealInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
`;

export const ModalWrapperContainer = styled.div`
    position: relative;
`;

export const HeaderContentRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    color: #000;
`;

export const BackButtonRow = styled.div<{ isNewAppWidthEnabled: boolean }>`
    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin-bottom: 0.625rem;
    margin: 0 auto;
    padding: 0 0.5rem;
`;

export const HeaderContent = styled.div`
    background-color: #ffffff;

    @media (min-width: ${BreakPoint.PHONE}) {
        padding: 1rem;
    }
`;

export const BridgeBarContainer = styled.div<{ isNewAppWidthEnabled: boolean }>`
    width: 100%;
    padding-top: 3px;

    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin: 0 auto;
`;

export const TopBarContainer = styled.div`
    background-color: #ffffff;
    display: flex;
    padding: 0px;
`;

export const HamburgerMenuContainer = styled.div`
    width: 40px;
`;
