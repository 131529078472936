import { ReactNode } from 'react';
import { Provider, useStore } from 'react-redux';
import { Store } from 'redux';
import { RealState } from '../types/scopeState';
import { isScopedStore, ScopedStore } from '../store/scopedStoreManager';
import { isScopedStoreEnabled } from './stateScopeContext';

export const RawStateProvider = ({ children }: { children: ReactNode }) => {
    const store: ScopedStore | Store<RealState> = useStore();
    const rawStore = isScopedStore(store) ? store.__realStore : store;

    const scopedStoreEnabled = isScopedStoreEnabled(rawStore);

    if (!scopedStoreEnabled) {
        return <>{children}</>;
    }

    return <Provider store={rawStore}>{children}</Provider>;
};
