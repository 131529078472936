import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { ModalDialog } from '@interstate-beta/components/ModalDialog';
import { Button } from '@interstate-beta/components/Button';
import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial';
import { offerActionCreators, offerInfoSelectors } from '@makemydeal/dr-dash-store';
import { Typography } from '@interstate-beta/components/Typography';
import { UpdateOfferDialogProps } from './UpdateOfferDialogProps';
import { UpdateOfferDialogMode } from './UpdateOfferDialogMode';
import { ErrorModalDialogContent } from '../common/ErrorModalDialogContent';
import { LoaderWrapper } from './UpdateOfferDialog.style';

export const UpdateOfferDialogInterstate = ({ show, onHide, onSuccess }: UpdateOfferDialogProps) => {
    const dispatch = useDispatch();
    const [mode, setMode] = useState(UpdateOfferDialogMode.SAVING);
    const offerIsBeingSaved = useSelector(offerInfoSelectors.getIsOfferBeingSaved);
    const offerHasBeenSaved = useSelector(offerInfoSelectors.getHasBeenSaved);

    // Set mode when offer save status changes
    React.useEffect(() => {
        if (show) {
            if (offerIsBeingSaved === true) {
                return setMode(UpdateOfferDialogMode.SAVING);
            }

            if (offerIsBeingSaved === false && offerHasBeenSaved === true) {
                setMode(UpdateOfferDialogMode.SUCCESS);
                if (onSuccess) {
                    onSuccess();
                }
                return;
            }

            if (offerIsBeingSaved === false && offerHasBeenSaved === false) {
                setMode(UpdateOfferDialogMode.ERROR);
                return;
            }
        }
    }, [offerIsBeingSaved, offerHasBeenSaved]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRetryClick = () => {
        dispatch(offerActionCreators.saveOffer());
    };

    const header = <Typography variant="h4">Update Offer</Typography>;

    switch (mode) {
        case UpdateOfferDialogMode.SAVING:
            return (
                <ModalDialog
                    size="small"
                    data-testid="update-offer-dialog-saving"
                    show={show}
                    header={header}
                    onHide={onHide}
                    footer={
                        <LoaderWrapper>
                            <LoadingIndicator size="small" data-testid="update-offer-loading-indicator" />
                        </LoaderWrapper>
                    }
                >
                    <Typography variant="body-md" tag="p">
                        We're updating your changes to this offer.
                    </Typography>
                </ModalDialog>
            );

        case UpdateOfferDialogMode.ERROR:
            return (
                <ModalDialog
                    size="small"
                    data-testid="update-offer-dialog-error"
                    show={show}
                    header={header}
                    onHide={onHide}
                    footer={
                        <Button
                            data-testid="update-offer-retry-button"
                            onClick={handleRetryClick}
                            size="small"
                            buttonStyle="primary"
                        >
                            Retry
                        </Button>
                    }
                >
                    <ErrorModalDialogContent />
                </ModalDialog>
            );
        default: {
            return null;
        }
    }
};
