import { ArrowTopRightOnSquareIcon } from '@interstate-beta/components/Icons';
import { configSelectors, creditAppSelectors, creditDecisionSelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { useSelector } from 'react-redux';
import CreditDecisionTerms from './CreditDecisionTerms';
import {
    CardContainer,
    DealertrackContainer,
    DeepLinkDealertrack,
    DescriptionInfo,
    FinancingContainer,
    FinancingTitle,
    LayoutContainer,
    LenderDecisionContainer,
    LenderInfoContainer,
    SendLinkContainer,
    SendLinkWrapper
} from './LenderDecisionInfo.style';
import { LenderList } from './LenderList';
import { CreditDecision } from '@makemydeal/dr-dash-types';

const LenderDecisionInfo = () => {
    const toggleFsDealRefId = useSelector(featureToggleSelectors.isToggleFsDealRefIdEnabled);
    const creditProviderIsRouteOne = useSelector(dealerSelectors.isCreditProviderRouteOne);
    const isRouteOneCreditBureauHidden = useSelector(featureToggleSelectors.enableHideRouteOneCreditBureau);
    const updateCreditProviderDeeplink = creditProviderIsRouteOne && isRouteOneCreditBureauHidden;
    const dealRefId = useSelector(offerSelectors.getDealRefId);
    const isCreditAppCompleted = useSelector(creditAppSelectors.isCreditAppCompleted);
    const submittedOn = useSelector(creditAppSelectors.getCreditAppSubmittedOn);
    const configUrl = useSelector(configSelectors.getDealertrackUrl);
    const lenderDecisions = useSelector(creditDecisionSelectors.getLenderCreditDecisions);
    const numberLenderDecisions = lenderDecisions.length;
    // TODO: add parseTimeStampToMMDDYYYY to dr-common-utils later
    const parseTimeStampToMMDDYYYY = (timeStamp: number) => {
        const dateFormat = new Date(timeStamp * 1000);
        const dateStringFormat = `${dateFormat.getMonth() + 1}/${dateFormat.getDate()}/${dateFormat.getFullYear()}`;
        return dateStringFormat;
    };

    const timeStampFormatter = submittedOn !== 0 ? parseTimeStampToMMDDYYYY(submittedOn) : submittedOn;

    const getDealerTrackDeepLink = () => {
        let url: string;
        if (updateCreditProviderDeeplink) {
            url = 'https://www.routeone.net/';
        } else {
            const fsProvider = toggleFsDealRefId ? 'FD' : 'DRS';
            url = `${configUrl}/view_application/${fsProvider}/${dealRefId}/`;
        }
        return url;
    };

    return (
        <LenderDecisionContainer data-testid="lender-decision-layout">
            <DescriptionInfo>
                <FinancingContainer>
                    <FinancingTitle>Financing Terms</FinancingTitle>
                    <DealertrackContainer>
                        <DeepLinkDealertrack href={getDealerTrackDeepLink()} target="_blank" data-testid="dealerTrack-link">
                            <div>
                                <ArrowTopRightOnSquareIcon />
                            </div>
                            {updateCreditProviderDeeplink ? <span>Open RouteOne</span> : <span>Open Dealertrack</span>}
                        </DeepLinkDealertrack>
                    </DealertrackContainer>
                </FinancingContainer>
                <SendLinkContainer data-testid="send-link-container">
                    <SendLinkWrapper>
                        <a>Send Link</a>
                    </SendLinkWrapper>
                    {isCreditAppCompleted && timeStampFormatter ? (
                        <span>Credit Application Completed - {timeStampFormatter}</span>
                    ) : (
                        <span>Credit Application Not Submitted</span>
                    )}
                </SendLinkContainer>
            </DescriptionInfo>

            <LayoutContainer>
                <CardContainer>
                    <span className="lenderDecisionTitle">Lender Decisions ({numberLenderDecisions})</span>
                    <LenderList lenderDecisions={lenderDecisions} sortOrder={['approvalStatus']} />
                </CardContainer>
                <LenderInfoContainer>
                    <CreditDecisionTerms />
                </LenderInfoContainer>
            </LayoutContainer>
        </LenderDecisionContainer>
    );
};

export default LenderDecisionInfo;
