// externals
import { useDispatch } from 'react-redux';

// libraries
import { deskingActionCreators, sendToCustomerDialogActionCreators } from '@makemydeal/dr-dash-store';
import { SendDialogTypes } from '@makemydeal/dr-dash-types';

// utils

// components
import { Modal } from '@interstate/components/Modal';
import { Typography } from '@interstate/components/Typography';
import SendToCustomerDialogFooterInterstate from '../sendToCustomerDialogFooter/SendToCustomerDialogFooter.interstate';

import { LinkIcon } from '@interstate/components/Icons';

// consts/enums
import { SHARE_URL_OPTION_LABEL } from '../constants';

// interfaces/types

// styles
import { ToggleGroup } from '@interstate-beta/components/ToggleGroup/ToggleGroup/ToggleGroup';
import { ContentContainer } from './SendToCustomerDialog.style';
export type SendToCustomerDialogProps = {
    show: boolean;
    onHide(): void;
    shareUrl: string;
    dialogHeader: string;
    dialogPrompt: string;
    dialogType: SendDialogTypes;
};

const SendToCustomerDialogInterstate = (props: SendToCustomerDialogProps) => {
    const { show, onHide, shareUrl, dialogHeader, dialogPrompt, dialogType } = props;

    const prefix = 'send-to-customer-dialog';
    const dispatch = useDispatch();

    const options = [
        {
            icon: <LinkIcon />,
            children: (
                <Typography variant="anchor-block-md" color={'base.color.black'}>
                    {SHARE_URL_OPTION_LABEL}
                </Typography>
            ),
            value: 'url',
            selected: true,
            'data-testid': `${prefix}-share-option-url`
        }
    ];

    // istanbul ignore next
    const handleShareUrlChange = () => null;

    const handleCopyClick = () => {
        if (dialogType === SendDialogTypes.Offer) {
            dispatch(deskingActionCreators.copyDealUrl());
        } else {
            dispatch(sendToCustomerDialogActionCreators.copyActivityShareUrl(dialogType));
        }

        navigator.clipboard.writeText(shareUrl);
    };

    const header = <div data-testid={`${prefix}-header`}>{dialogHeader}</div>;

    const footer = (
        <SendToCustomerDialogFooterInterstate
            prefix={prefix}
            shareUrl={shareUrl}
            handleCopyClick={handleCopyClick}
            handleShareUrlChange={handleShareUrlChange}
        />
    );

    return (
        <Modal
            size="large"
            id={prefix}
            data-testid={prefix}
            show={show}
            sx={{
                '.interstate-x-mark-icon': {
                    cursor: 'pointer'
                }
            }}
            header={
                <Typography variant="h3" color="base.color.blue.900">
                    {header}
                </Typography>
            }
            onHide={onHide}
        >
            {/* TODO: [US1141319] Remove ContentContainer padding override once interstate fixes this issue */}
            <ContentContainer>
                <Typography variant="body-md" tag="span" data-testid={`${prefix}-prompt`}>
                    {dialogPrompt}
                </Typography>
                <ToggleGroup data-testid={`${prefix}-share-options`} size="medium" toggleButtonOptions={options} />
                {footer}
            </ContentContainer>
        </Modal>
    );
};

export default SendToCustomerDialogInterstate;
