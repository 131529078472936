export interface MockDataItem {
    sellRate: number;
    id: string;
    netCashDown: number;
    dueAtSigning: number;
    downPayment: number;
    monthlyPayment: number;
    isSelected: boolean;
    isLoading?: boolean;
}

export const mockData: MockDataItem[][] = [
    [
        {
            sellRate: 1.99,
            id: 'finance_term_39_downpayment_854',
            netCashDown: 854,
            dueAtSigning: 854,
            downPayment: 854,
            monthlyPayment: 855.95,
            isSelected: false,
            isLoading: true
        },
        {
            sellRate: 1.99,
            id: 'finance_term_44_downpayment_854',
            netCashDown: 854,
            dueAtSigning: 854,
            downPayment: 854,
            monthlyPayment: 761.8,
            isSelected: false
        },
        {
            sellRate: 1.99,
            id: 'finance_term_49_downpayment_854',
            netCashDown: 854,
            dueAtSigning: 854,
            downPayment: 854,
            monthlyPayment: 686.87,
            isSelected: false,
            isLoading: true
        }
    ],
    [
        {
            sellRate: 1.99,
            id: 'finance_term_39_downpayment_2854',
            netCashDown: 2854,
            dueAtSigning: 2854,
            downPayment: 2854,
            monthlyPayment: 802.95,
            isSelected: false
        },
        {
            sellRate: 1.99,
            id: 'finance_term_44_downpayment_2854',
            netCashDown: 2854,
            dueAtSigning: 2854,
            downPayment: 2854,
            monthlyPayment: 714.63,
            isSelected: true
        },
        {
            sellRate: 1.99,
            id: 'finance_term_49_downpayment_2854',
            netCashDown: 2854,
            dueAtSigning: 2854,
            downPayment: 2854,
            monthlyPayment: 644.34,
            isSelected: false
        }
    ],
    [
        {
            sellRate: 1.99,
            id: 'finance_term_39_downpayment_4854',
            netCashDown: 4854,
            dueAtSigning: 4854,
            downPayment: 4854,
            monthlyPayment: 749.95,
            isSelected: false
        },
        {
            sellRate: 1.99,
            id: 'finance_term_44_downpayment_4854',
            netCashDown: 4854,
            dueAtSigning: 4854,
            downPayment: 4854,
            monthlyPayment: 667.46,
            isSelected: false
        },
        {
            sellRate: 1.99,
            id: 'finance_term_49_downpayment_4854',
            netCashDown: 4854,
            dueAtSigning: 4854,
            downPayment: 4854,
            monthlyPayment: 601.81,
            isSelected: false
        }
    ]
];
