// externals
import { useSelector } from 'react-redux';

// libraries
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { DealActivityInterstate, DealComparisonInterstate, DealDatesAndStatus } from '@makemydeal/dr-dash-components';

// components
import { DealDashboardContainer, InterstateDealActivityContainer } from './DeskingActivity.styles';

// selectors
import { dealLifecycleSelectors } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// utils
import { useInterstateToken } from '@interstate/components/InterstateThemeProvider';
import { LenderChangeAlert } from './LenderChangeAlert';
import { DeskingActivityDeal } from './deskingActivity/DeskingActivityDeal';

const DeskingActivityInterstate = () => {
    const deskingActivityBackgroundColor = useInterstateToken()('base.color.gray.50');

    const dealLifecycle = useSelector(dealLifecycleSelectors.getDealLifecycle);
    const isDealLifeCycleEnabled = useSelector(featureToggleSelectors.isDealLifeCycleEnabled);

    return (
        <Grid container data-testid="activities-desking" spacing={2}>
            <InterstateDealActivityContainer
                bgcolor={deskingActivityBackgroundColor}
                container
                flexDirection="column"
                data-testid="deal-activity-container"
                xs={3}
                gap="8px"
            >
                {/* TODO: US1183743 - Use interstate typography for this h3 */}
                <h3
                    style={{
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '120%',
                        textTransform: 'capitalize'
                    }}
                >
                    Deal Progress
                </h3>
                <DealActivityInterstate className="hidden-xs hidden-sm hidden-md" />
            </InterstateDealActivityContainer>
            <Grid xs paddingY="40px">
                <LenderChangeAlert />
                <DealDashboardContainer>
                    <Typography variant="h1">Deal Dashboard</Typography>
                    {isDealLifeCycleEnabled && dealLifecycle.status && (
                        <Grid container justifyContent="flex-end">
                            <DealDatesAndStatus />
                        </Grid>
                    )}
                </DealDashboardContainer>
                <DealComparisonInterstate />
                <DeskingActivityDeal />
            </Grid>
        </Grid>
    );
};

export default DeskingActivityInterstate;
